import React from 'react';
import { createBrowserHistory } from 'history'
import { MDBDataTable,MDBIcon,MDBBtn,MDBRow,MDBCol, MDBInput ,MDBModal,MDBModalHeader,MDBModalBody,MDBModalFooter} from 'mdbreact';
import {Switch , Route } from 'react-router-dom';
// import {browserHistory} from 'react-router';
import { Link } from "react-router";
import  $ from "jquery";
import * as baseUrl from './constants';
import { hasRole, hasPermission } from './auth';
import * as jwtJsDecode from 'jwt-js-decode';

const history = createBrowserHistory();
const location = history.location;

class Header extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            active : this.props.activetab,
            userName:"",
            title: this.props.page_title,
            modelswitch:false,
            modelDelete:false,
            orgArray:[],
            orgName:"",
            choosenTenant:"",
            modalsession:false,
            disableMenu:"disabled-link",
            switch_org_name:"",
            disable_switchacc:"disabled-link",
            logoff_Account:"none",
            UserTenant:localStorage.getItem("UserTenant"),
            user:{
                permissions: [
                   
                ],
            },
            changepwd: false,
            password:'',
            confirmPassword:'',
            pwdsuccess:false,
            errorMessage:'',
            pwderror:false
        };
        this.logOut = this.logOut.bind(this);
        this.showSidebar = this.showSidebar.bind(this);
        this.switchAccount = this.switchAccount.bind(this);
        this.SwitchAcctoggle = this.SwitchAcctoggle.bind(this);
        this.switchAccountOrgChange = this.switchAccountOrgChange.bind(this);
        this.togglesession = this.togglesession.bind(this);
        this.sessiontagOk = this.sessiontagOk.bind(this);
        this.LogOffAccount = this.LogOffAccount.bind(this);
        this.SwitchOkAcctoggle = this.SwitchOkAcctoggle.bind(this);
        this.DeleteAcctoggle = this.DeleteAcctoggle.bind(this);
        this.deleteAccount = this.deleteAccount.bind(this);
        this.deleteAccountApi = this.deleteAccountApi.bind(this);
        this.closeOkPopup = this.closeOkPopup.bind(this);
        this.toggledeletesuccess= this.toggledeletesuccess.bind(this);
        this.togglepwd=this.togglepwd.bind(this);
        this.updatePwd=this.updatePwd.bind(this);
    }
    togglepwderror=()=>{
      this.setState({pwderror: !this.state.pwderror});
    }
    togglepwdupdate=()=>{
      this.setState({pwdsuccess: !this.state.pwdsuccess});
    }
    updatePwd(event) {
      event.preventDefault();  
       var oldpwd= this.state.password;
       var newpwd= this.state.confirmPassword;
       var validPassword = false;

       if(oldpwd==''){
         $('.error_pwd').text("Old password should not be empty");
       }
       if(newpwd==''){
        $('.error_cpwd').text("New password should not be empty");
       }
       if (newpwd != '') {        
        if(newpwd.length <= 20) {
          if (!newpwd.match("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&+=])[A-Za-z\\d!@#$%^&+=]{8,}$")) {
            
            $('.entervalidpwd').text("Password must have at least 8 characters that include at least 1 lowercase character (a-z), 1 uppercase character (A-Z), 1 number (0-9) and 1 special character (!@#$%^&+=)");
          } else {
            validPassword = true;
          }
        } else {
          $('.pwdexceedchars').text("Password must not be more than 20 characters.");
          // errors["pwdexceedchars"] = "Password must not be more than 20 characters."
        }

        // if (!newpwd.match("(?=.*[0-9])"
        //   + "(?=.*[a-z])(?=.*[A-Z])"
        //   + "(?=.*[!@#$%^&+=])"
        //   + "(?=\\S+$).{8,128}$")) {
        //     $('.error_cpwd').text("Password must have at least 8 characters that include at least 1 lowercase character, 1 uppercase characters, 1 number and 1 sepcial character (!@#$%^&+=).");
        //   validPassword = false;
        // }
      }
        if(newpwd!='' && oldpwd!='' && validPassword){
         let url =  baseUrl.URLPath+"v1.0/user/updatepassword";
           let data = {"oldPassword": oldpwd, "newPassword":newpwd}; 
            fetch(url, {
           method: 'POST', // or 'PUT'
           body: JSON.stringify(data), // data can be `string` or {object}!
            headers:{
              "content-type":"application/json",
              "Authorization":localStorage.getItem("Authorization"),
            }
           }).then(resp =>{
                return resp.json();
           }).then((response) =>{
             if (response != undefined) {
               if(response.status==400){
                if(response.message == "password_not_strong") {
                  $('.entervalidpwd').text("Password must have at least 8 characters that include at least 1 lowercase character (a-z), 1 uppercase character (A-Z), 1 number (0-9) and 1 special character (!@#$%^&+=)");
                } else {
                  this.setState({
                    errorMessage: response.message
                  });
                }
                 this.togglepwderror();
               } else if(response.status=="SUCCESS"){
                this.togglepwd();
                this.togglepwdupdate();
               } else if(response.status=="FAILURE"){
                this.setState({
                  errorMessage: response.errorMessage
                });
                this.togglepwderror();
              }
               
             }
           }).catch((error) => {
                console.log(error, "catch the loop")
            })
        }      
   }

    togglepwd=()=>{
      this.setState({
        changepwd: !this.state.changepwd,
        password:"",
        confirmPassword:""
      });
    }
    toggledeletesuccess = () => {
      this.setState({
        modal: !this.state.modal
      });
      
    }

    closeOkPopup(){
      history.push('/');
       window.location.reload();
    }
    deleteAccountApi(){
      debugger;
      var errors;
     this.setState({
      modelDelete: !this.state.modelDelete
     });
  
    var url =  baseUrl.URLPath + "v1.0/user/account";
    //localhost:8080/api/v1.0/user/account
     fetch(url, 
           {
             method: 'DELETE', // or 'PUT'
            //  body: JSON.stringify("NULL"), // data can be `string` or {object}!
             headers:{
                   'Content-Type': 'application/json',
                   "Authorization":localStorage.getItem("Authorization"),
                 }
           }
         ).then(resp =>{
           debugger
           if(resp.status==401){
              this.togglesession();
              localStorage.clear();
            }else if(resp.status==404){
               this.setState({errors: errors});
               return resp.json();
             } else if(resp.status==500){
               alert("internal server error")
             } else if(resp.status==400){
               alert("Bad request")
             }else if (resp.status == 200){
               this.setState({
                 modal: !this.state.modal
               });
           
             }
             else{}
       })
        .catch(error => alert('Error:' + error));
        this.setState({errors: errors});
    }
  
    SwitchOkAcctoggle(){

       if(this.state.identifier!=""){
       localStorage.setItem("switchOrgName",  this.state.switch_org_name);

     this.state.logoff_Account = "block";
  
      // logoff_account
      var url = baseUrl.URLPath + "switchaccount/";
  
       fetch(url, {
        method: "GET",
        headers: {
                    'Content-Type': 'application/json',
                    "Authorization":localStorage.getItem("Authorization"),
                    "stenantid":this.state.identifier,
                  }
        })
        .then((resp) => {
          debugger;
          if(resp.status==401){
           
          }
          if(resp.status==200){
            return resp.json();
          }
        }) 
        .then((response) =>{
          debugger
          if(response!=undefined){
            let getViewDetails = localStorage.getItem("saveDetails")
            if(response.status=="SUCCESS" && getViewDetails == "true"){
              localStorage.setItem("saveDetails", false);
              localStorage.setItem("Authorization", "Bearer "+response.token);
              localStorage.setItem("tenant", this.state.identifier);
              localStorage.setItem("choosen_tenant", this.state.identifier);
              // history.push('/dashboard')
              window.location.reload();
            }
           else if(response.status=="SUCCESS"){
              localStorage.setItem("saveDetails", false);
              localStorage.setItem("Authorization", "Bearer "+response.token);
              localStorage.setItem("tenant", this.state.identifier);
              localStorage.setItem("choosen_tenant", this.state.identifier);
              window.location.reload();
            }
           }
        })
        .catch((error) => {
            console.log(error, "catch the loop")
        })
  
        this.setState({
          modelswitch: !this.state.modelswitch
        });
      }
    }
    
    switchAccountOrgChange(event){
      this.state.identifier = event.target.value;
      var index = event.nativeEvent.target.selectedIndex;
      var switch_org_name = event.nativeEvent.target[index].text
      this.state.switch_org_name = switch_org_name;
      // localStorage.setItem("switchOrgName",  this.state.switch_org_name);
    }
  
    LogOffAccount(event){
      this.state.identifier = event.target.value;
      var url = baseUrl.URLPath + "loggedoff/";
       fetch(url, {
        method: "GET",
        headers: {
                    'Content-Type':'application/json',
                    "Authorization": localStorage.getItem("Authorization"),
                  }
        })
        .then((resp) => {
          debugger;
          if(resp.status==401){
           
          }
          if(resp.status==200){
            return resp.json();
          }
        }) 
        .then((response) =>{
          debugger
          if(response!=undefined){
            if(response.status=="SUCCESS"){
              localStorage.setItem("Authorization", "Bearer "+response.token);
              localStorage.setItem("choosen_tenant","none");
              localStorage.setItem("tenant",localStorage.getItem("UserTenant"));
              localStorage.setItem("switchOrgName","");
              // history.push('/dashboard')
              window.location.reload();
            }
          }
        })
        .catch((error) => {
            console.log(error, "catch the loop")
        })
  
    }
  
  
    async togglesession(){
      debugger;
      this.setState({
      modalsession: !this.state.modalsession
      });
    }
  
    sessiontagOk(){
      // this.props.history.push('/');
      history.push('/');
      window.location.reload();
    }
  
    SwitchAcctoggle(){
      this.setState({
        modelswitch: !this.state.modelswitch
      });
      if(this.state.identifier!=""){
        this.state.identifier = localStorage.getItem("tenant");
      }
    }
    
    switchAccount(){
      this.SwitchAcctoggle()
    }

    DeleteAcctoggle(){
      this.setState({
        modelDelete: !this.state.modelDelete
      });
      // if(this.state.identifier!=""){
      //   this.state.identifier = localStorage.getItem("tenant");
      // }
    }
    
    deleteAccount(){
      this.DeleteAcctoggle()
    }
    
    logOut(){
      debugger
      localStorage.clear();
      history.push('/');
      window.location.reload();
    }
  
  componentWillMount(){
    debugger
    // this.state.choosenTenant = localStorage.getItem("choosen_tenant");
    // if(this.state.choosenTenant=="none" && hasPermission(this.state.user, ['SwitchAccount']) ){
    //     this.setState({
    //         disableMenu:"disabled-link"
    //     })
    // }
    // this.state.user.permissions=localStorage.getItem("roleAccess");
  
     var user= localStorage.getItem('user');
     this.setState({userName:user});
  
  }
  
  showSidebar(){
      var js = document.getElementsByClassName('sidebar-main-div');
      if($(js).hasClass("scroll_nav")){
        $(js).removeClass("scroll_nav");
        $('nav').removeClass("scroll_nav");
        $('#navbar').removeClass("nav_p");
      }else{
        $(js).addClass("scroll_nav");
        $('#navbar').addClass("nav_p");
      }
  }   
  
  componentDidMount(){
    debugger
    this.state.user.permissions = localStorage.getItem("roleAccess");
      if(hasPermission(this.state.user, ['SwitchAccount'])){
        
      this.state.disable_switchacc = "";
  
     
  }
  
      // 
      this.state.choosenTenant = localStorage.getItem("choosen_tenant");
      if(this.state.choosenTenant=="none" && hasPermission(this.state.user, ['SwitchAccount']) ){
          this.setState({
              disableMenu:""
          })
      }

    var url = baseUrl.URLPath + "admin/v1.0/account/all";
    fetch(url,{
      method: "GET",
      headers: {
            "content-type":"application/json",
            "Authorization":localStorage.getItem("Authorization")
          }
      })
      .then((resp) => {
        debugger;
        if(resp.status==401){
          // alert("Access token is expired, please login again")
          this.togglesession();
          localStorage.clear();
        }
        if(resp.status==200){
          return resp.json();
        } 
      }) 
      .then((response) =>{
        if(response!=undefined){
           this.setState({ orgArray:response.organisationDTOS })
        }
      })
      .catch((error) => {
          console.log(error, "catch the loop")
      })
  }


  showSidebar(){
    var js = document.getElementsByClassName('sidebar-main-div');
    if($(js).hasClass("scroll_nav")){
      $(js).removeClass("scroll_nav");
    }else{
      $(js).addClass("scroll_nav");
    }
}
  
      render(){
        if(localStorage.getItem("roleAccess")=="Inventory_R,CSR_R" && this.state.orgArray.length==0){
          this.state.orgName = localStorage.getItem("tenant");
        }
        
           let switch_org = localStorage.getItem("switchOrgName");
         
           let org_full_name = "";
           const organisation =this.state.orgArray.map((orgArray, index) => {
              var setOrg = false;
              var HideOrg = "";
              if(switch_org!="" && switch_org!= null && (switch_org.toLowerCase() == orgArray.company.toLowerCase())){
                  switch_org = orgArray.company;
                  switch_org = switch_org.split(" ");
                  switch_org = switch_org[0];
                  this.state.logoff_Account = "block";
                  setOrg = true
                  this.state.identifier = orgArray.identifier;
                  org_full_name = orgArray.company
              }
              if(this.state.UserTenant.toLowerCase() == orgArray.identifier.toLowerCase()){
                HideOrg = "none";
                org_full_name = orgArray.company;
                this.state.orgName = orgArray.company;
              }
              return(
                <option value={orgArray.identifier} selected={orgArray.identifier==this.state.UserTenant ? false : setOrg} style={{"display":HideOrg}} > {orgArray.company} </option>
              )
          })
        return (
                <>
                  <header>
                    {/* <!-- Navbar--> */}
                    <nav className="navbar fixed-top navbar-expand-md navbar-light white double-nav scrolling-navbar custom-navbar" >
                    {/* <!-- SideNav slide-out button --> */}
                    <div className="breadcrumb-dn slide-arrow" onClick={this.showSidebar}>
                        <p><i className="fas fa-bars" id="toggle_arrow" style={{color:"#00529e"}}> </i></p>
                    </div>
                    <div className="text-left w-15 pl-4">
                        <a href="/appRegestration" className="pl-0"><img src={process.env.PUBLIC_URL + "/assets/img/webasto.png"} style={{width: "120px" }} /></a>
                    </div>
                    {/* <!-- Navbar links--> */}
                    <div className="mr-auto pl-2 input-div">
                        <div className="input-group input-search ">
                            <i className="fas fa-search fa-sm"></i>
                            <input type="text" className="border-0 small" placeholder="Search for something..." aria-label="Search" aria-describedby="basic-addon2" />
                        </div>
                    </div>
                    {/* <!--Navigation icons--> */}
                    <span id="dpl-navbar-right-buttons" >
                        {/* <!--Navigation icons--> */}
                        <ul className="nav navbar-nav nav-flex-icons ml-auto">
                        <li className="nav-item orgnization_name pr-3">
                            Organization : { (localStorage.getItem("switchOrgName")=="" || localStorage.getItem("switchOrgName")==null) ? localStorage.getItem("tenant") : localStorage.getItem("switchOrgName") }
                        </li>
                            {/* <!-- Tools --> */}
                                <li id="navbar-static-tools" className="nav-item dropdown">
                                        <a className="nav-link" id="navbar-tools" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                             <label className="profile-pic" >
                                                <img src={process.env.PUBLIC_URL + "/assets/img/user.png"}  />
                                             </label>
                                             
                                            <span className="d-none d-xl-inline-block ml-1 profile-pic-span">{this.state.userName}</span>
                                            <i class="fas fa-chevron-down profile-pic-arrow"></i>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right drop_menu  pt-1 pb-1" aria-labelledby="userDropdown">
                  
                     {hasPermission(this.state.user, ['SwitchAccount']) && <>
                     <a className={ "dropdown-item cursor-p " + this.state.disable_switchacc }   onClick={this.switchAccount} >
                        <span className="fw-500">Switch Account</span>
                     </a>
                     </>
    }
    <a className={ "dropdown-item cursor-p " }   onClick={this.togglepwd} >
                        <span className="fw-500">Change Password</span>
                     </a>
     {/* <a className={ "dropdown-item cursor-p " }  onClick = {this.deleteAccount}  >
                        <span className="fw-500">Delete Account</span>
                     </a> */}
    {hasPermission(this.state.user, ['SwitchAccount']) && <>
                     <a className= {"dropdown-item cursor-p"} style={{"display":this.state.logoff_Account}} id="logoff_account"  onClick={this.LogOffAccount} >
                       {/* <i className="fa fa-exchange-alt mr-2" aria-hidden="true" ></i> */}
                       <span className="fw-500"> Log Off <b>{switch_org}</b></span>
                     </a>
                     </>
    }
                     <a className="dropdown-item cursor-p" href="/" onClick={this.logOut}>
                       {/* <i className="fa fa-sign-out-alt mr-2" aria-hidden="true"></i> */}
                       <span className="fw-500"> Sign Out </span>
                     </a>
                     {/* <a className="dropdown-item" href="#">My account</a> */}
                   </div>
                               </li>
                               {/* <!-- Login / register --> */}
                                </ul>
                            </span>
                        </nav>
                        {/* <!-- /.Navbar--> */}
                    </header>
                    <MDBModal isOpen={this.state.modelswitch} size="md" backdrop="static" className="model_top" data-backdrop="static" data-keyboard="false">
                        <MDBModalHeader toggle={this.SwitchAcctoggle}>Switch Account</MDBModalHeader>
                        <MDBModalBody>
                            <div class="row">
                                <label className="switch_acc" > Select Organization </label>
                                <div class="col-md-7">
                                <select className="browser-default custom-select select_height mb_8" id="organization" onChange={this.switchAccountOrgChange.bind(this)}>
                                    <option value=""> Select Organization </option>
                                    {organisation}
                                </select>
                                </div>
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                        <button type="button" data-test="button" onClick={this.SwitchAcctoggle} className="btn_cancel">Cancel <i class="fas fa-times ml-2"></i></button>
                        <button type="button" data-test="button" onClick={this.SwitchOkAcctoggle} className="btn_primary">OK</button>
                        </MDBModalFooter>
                        </MDBModal>
                       {/* popup for delete account */}
                        <MDBModal isOpen={this.state.modelDelete} size="md" backdrop="static" className="model_top" data-backdrop="static" data-keyboard="false">
                        <MDBModalHeader toggle={this.DeleteAcctoggle}>Confirmation</MDBModalHeader>
                        <MDBModalBody>
                            {/* <div class="row">
                                <label className="switch_acc" > Select Organization </label>
                                <div class="col-md-7">
                                <select className="browser-default custom-select select_height mb_8" id="organization" onChange={this.switchAccountOrgChange.bind(this)}>
                                    <option value=""> Select Organization </option>
                                    {organisation}
                                </select>
                                </div>
                            </div> */}
                            Are you sure you want to delete?<br></br><br></br>
                           {/* <div class="row"> */}
                             Note:The user has two weeks, to activate his account again. After the two weeks, the account gets deleted permanently.
                           {/* </div> */}
                        </MDBModalBody>
                        <MDBModalFooter>
                        <button type="button" data-test="button" onClick={this.DeleteAcctoggle} className="btn_cancel">Cancel <i class="fas fa-times ml-2"></i></button>
                        <button type="button" data-test="button" onClick={this.deleteAccountApi} className="btn_primary">YES</button>
                        </MDBModalFooter>
                        </MDBModal>
                        {/* popup for session expire */}
                        <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
                        <MDBModalHeader toggle={this.togglesession}>Session has expired</MDBModalHeader>
                        <MDBModalBody>
                            Please login again.
                        </MDBModalBody>
                        <MDBModalFooter>
                        {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
                        <button type="button" data-test="button" onClick={this.sessiontagOk} className="btn_primary">OK</button>
                        </MDBModalFooter>
                    </MDBModal>
                  {/* popup for sucessfull delete */}
                  <MDBModal isOpen={this.state.modal} toggle={this.toggledeletesuccess} size="md" className="model_top">
      <MDBModalHeader toggle={this.toggledeletesuccess}>Success</MDBModalHeader>
      <MDBModalBody>
        Account deleted successfully.
      </MDBModalBody>
      <MDBModalFooter>
      {/* <MDBBtn color="secondary" onClick={this.toggle}>Close</MDBBtn> */}
      <button type="button" data-test="button" onClick={this.closeOkPopup} className="btn_primary">OK</button>
      </MDBModalFooter>
      </MDBModal>
            <MDBModal isOpen={this.state.changepwd} toggle={this.togglepwd} size="md" className="model_top">
              <div data-test="modal-header" class="modal-header">
                <div class="modal-title fw-800 mt-1" ref={(node) => { if (node) { node.style.setProperty("font-size", "18px", "important"); } }}>Change Password</div>
                <button type="button" data-test="button" class="close" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <MDBModalBody>
                <form
                  className="needs-validation"
                  onSubmit={this.submitHandler}
                  noValidate
                >
                  <div className="row" style={{ marginTop: '0.05rem' }}>
                    <MDBCol md="5" >
                      <label className="pull-left sel_lbl">Old Password:</label>
                    </MDBCol>
                    <MDBCol md="5" className="mt-y12">
                      <MDBInput
                        name="password"
                        value={this.state.password}
                        onChange={(e)=>{this.setState({password: e.target.value}); $('.error_pwd').text("");}}
                        type="password"
                        id="password"
                        autoComplete="off"
                      >
                        <span className="error_msg w3-animate-top error_pwd" style={{ color: "red" }}></span> 
                      </MDBInput>
                    </MDBCol>
                  </div>
                  <div className="row" style={{ marginTop: '0.05rem' }}>
                    <MDBCol md="5" >
                      <label className="pull-left sel_lbl">New Password:</label>
                    </MDBCol>
                    <MDBCol md="5" className="mt-y12">
                      <MDBInput
                        name="Cpassword"
                        value={this.state.confirmPassword}
                        onChange={(e)=>{this.setState({confirmPassword: e.target.value}); $('.error_cpwd').text("");$('.entervalidpwd').text("");$('.pwdexceedchars').text("");}}
                        type="password"
                        id="Cpassword"
                        autoComplete="off"
                      >
                        <span className="error_msg w3-animate-top error_cpwd" style={{ color: "red" }}></span>                       
                        <span className="error_msg w3-animate-top entervalidpwd" style={{ color: "red" }}></span>
                        <span className="error_msg w3-animate-top pwdexceedchars" style={{ color: "red" }}></span>
                      </MDBInput>
                    </MDBCol>
                  </div>
                </form>
              </MDBModalBody>
              <MDBModalFooter>
                <button type="button" data-test="button" onClick={this.updatePwd} className="btn_primary">Update</button>
              </MDBModalFooter>
            </MDBModal>
            <MDBModal isOpen={this.state.pwdsuccess} size="md" backdrop="static" className="model_top" data-backdrop="static" data-keyboard="false">
              <MDBModalHeader toggle={this.togglepwdupdate}>Success</MDBModalHeader>
              <MDBModalBody>
                Your Password has been updated successfully.
              </MDBModalBody>
              <MDBModalFooter>
                <button type="button" data-test="button" onClick={this.logOut} className="btn_primary">OK</button>
              </MDBModalFooter>
            </MDBModal>
            <MDBModal isOpen={this.state.pwderror} toggle={this.togglepwderror} size="md" className="model_top" >
              <MDBModalHeader toggle={this.togglepwderror}>Error</MDBModalHeader>
              <MDBModalBody>
                {this.state.errorMessage}
              </MDBModalBody>
              <MDBModalFooter>
                <button type="button" data-test="button" onClick={this.togglepwderror} className="btn_primary">OK</button>
              </MDBModalFooter>
            </MDBModal>
                </>
        )
    }
}

export default Header;