import React from 'react';
import { MDBInput, MDBCol, MDBRow, MDBModal, MDBBtn, MDBModalHeader, MDBModalBody, MDBModalFooter } from "mdbreact";
import Headers from './header';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Table, Check, Card, Accordion } from 'react-bootstrap';
import * as baseUrl from './constants';
import { Link } from 'react-router-dom';
import $ from "jquery";
import { Alert } from 'reactstrap';
import { isIP } from 'is-ip';
import { createBrowserHistory } from 'history';
import appRegestration from './appRegestration';
import Switch from "react-switch";

const history = createBrowserHistory();


class editRegestration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sucess1: false,
      colour: 'sucess',
      message: "",
      fields: { applicationType: '', applicationName: '', domain: '', description: '', redirectUrl: '', organisation: '', id: '', active: '', city: '', company: '', country: '', createdBy: '', createdDate: '', identifier: '', latitude: '', longitude: '', officeAddress: '', organisationType: '', root: '', state: '', updatedBy: '', updatedDate: '', url: '', isRedirection: false },
      sucess: {},
      errors: {},
      activeItem: "1",
      model: "false",
      page_title: "Add User",
      AppId: "",
      dataArray: [],
      modalsession: false,
      getDownloadedText: "",
      applicationError: '',
      applicationFailure: false,
      validationErrors: [],
      validationAppErrors: [],
      collapseID: "",
      controllerData: [],
      checked: false,
      selectAll: false,
      disabled: true,
      removedAPIsAccess: [],
      newAPIs: [],
      list: [],
      activeArray: [],
      controllerArray: [
        "Organisation-Controller_1"
      ],
      isRedirection: false,
      selectedList: [],
      controllerDataAccess: []
    }
    this.toggle = this.toggle.bind(this)
    this.tagOk = this.tagOk.bind(this)
    this.addNew = this.addNew.bind(this)
    this.backToList = this.backToList.bind(this)
    this.changeHandler = this.changeHandler.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.applicationTypeChange = this.applicationTypeChange.bind(this)
    this.activeChange = this.activeChange.bind(this)
    this.togglesession = this.togglesession.bind(this)
    this.onShowAlert = this.onShowAlert.bind(this)
    this.downloadCsv = this.downloadCsv.bind(this)
    this.organizationChange = this.organizationChange.bind(this)
    this.addRegestertoggle = this.addRegestertoggle.bind(this)
    this.changeOrgAdd = this.changeOrgAdd.bind(this)
    this.addOrganizationSubmit = this.addOrganizationSubmit.bind(this)
    this.toggleSuccess = this.toggleSuccess.bind(this)
    this.toggleCollapse = this.toggleCollapse.bind(this)
    this.handleChange = this.handleChange.bind(this);
    this.chkhandleChange = this.chkhandleChange.bind(this);
    this.activeAllChange = this.activeAllChange.bind(this);
  }

  toggleFailure = () => {
    this.setState({
      applicationFailure: !this.state.applicationFailure
    });
  }


  handleChange(checked, event, id) {
    this.state.disabled = false;
    let cid = id.substr(0, id.indexOf('_'));

    debugger
    if (checked == true) {
      this.state.controllerArray.push(id);
      this.setState({
        activeArray: this.state.controllerArray
      });
    } else {
      var fdf = this.state.controllerArray.index;
      const index = this.state.controllerArray.indexOf(id);
      if (index > -1) {
        this.state.controllerArray.splice(index, 1);
      }
      this.state.controllerData.map((controllerall, index) => {
        this.state.controllerDataAccess.accessedAPIs.map((controllerselected, index) => {
          if (controllerall.controller == controllerselected.controller && controllerall.apis.length == controllerselected.apis.length) {
            const index1 = this.state.list.indexOf(cid);
            if (index1 > -1) {
              this.state.list.splice(index1, 1);
            }
          }
        });
      })


      this.setState({
        activeArray: this.state.controllerArray
      });
    }


  }

  successOk() {
    window.location.reload();
  }
  toggleSuccess = () => {
    this.setState({
      modalSuccess: !this.state.modalSuccess
    });
  }
  closetoggle = () => {
    this.setState({
      modalRegester: !this.state.modalRegester
    });
  }
  validateURL(textval) {
    var urlregex = new RegExp("^((http|https)://)(www.)?[a-zA-Z0-9@:%-._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)");
    var var_name = urlregex.test(textval);
    return var_name;
    // alert(var_name)
  }

  validateIPAddress(ipaddress) {
    var var_name = isIP(ipaddress);
    return var_name;
  }

  async addOrganizationSubmit(event) {
    event.preventDefault();
    debugger;
    let errors = {};
    var company = this.state.fields['company'];
    if (company != "" && company != null && company != undefined) {
      company = company.trim();
    }
    var identifier = this.state.fields['identifier'];
    if (identifier != "" && identifier != null && identifier != undefined) {
      identifier = identifier.trim();
    }
    var officeAddress = this.state.fields['officeAddress'];
    if (officeAddress != "" && officeAddress != null && officeAddress != undefined) {
      officeAddress = officeAddress.trim();
    }
    var city = this.state.fields['city'];
    if (city != "" && city != null && city != undefined) {
      city = city.trim();
    }
    var state = this.state.fields['state'];
    if (state != "" && state != null && state != undefined) {
      state = state.trim();
    }
    var country = this.state.fields['country'];
    if (country != "" && country != null && country != undefined) {
      country = country.trim();
    }
    var orgurl = this.state.fields['url'];
    if (orgurl != "" && orgurl != null && orgurl != undefined) {
      orgurl = orgurl.trim();
    }
    if (company == '') {
      errors["companyempty"] = "Please enter a organization.";
    } else if (company != '' && this.isHaveAlphabetic(company) == false) {
      errors["companyempty"] = "Organization Name must contain one alphabetical letter";
    } else if (company != '' && /[`!$%&*()+\=\{};':"\\|,.\[\]<>\/?~#@^]/.test(company) == true) {
      errors["companyempty"] = "Organization Name must not contain special characters";
    } else if (company != '' && this.isLengthAllowed(company) == false) {
      errors["companyempty"] = "Max length should be 128 characters";
    }
    if (city == '') {
      errors["cityempty"] = "Please enter a city.";
    } else if (city != '' && this.isHaveAlphabetic(city) == false) {
      errors["cityempty"] = "City Name must contain one alphabetical letter";
    } else if (city != '' && /[`!$%&*()+\=\{};':"\\|,.<>\/?~#@_^]/.test(city) == true) {
      errors["cityempty"] = "City Name must not contain special characters other than []-";
    } else if (city != '' && this.isLengthAllowed(city) == false) {
      errors["cityempty"] = "Max length should be 128 characters";
    }

    if (state == '') {
      errors["stateempty"] = "Please enter a state.";
    } else if (state != '' && this.isHaveAlphabetic(state) == false) {
      errors["stateempty"] = "State Name must contain one alphabetical letter";
    } else if (state != '' && /[`!$%&*()+\=\{};':"\\|,.<>\/?~#@_^]/.test(state) == true) {
      errors["stateempty"] = "State Name must not contain special characters other than []-";
    } else if (state != '' && this.isLengthAllowed(state) == false) {
      errors["stateempty"] = "Max length should be 128 characters";
    }

    if (orgurl == '') {
      errors["urlempty"] = "Please enter a url.";
    }
    if (this.validateURL(orgurl) == false) {
      errors["urlempty"] = "Please enter a valid url.";
    }
    if (identifier == "") {
      errors["identifierempty"] = "Please enter a Short Name.";
    } else if (identifier != '' && this.isHaveAlphabetic(identifier) == false) {
      debugger;
      errors["identifierempty"] = "Short Name must contain one alphabetical letter";
    } else if (identifier != '' && /[`!$%&*()+\=\{}\[\];':"\\|,.<>\/?~#@_0123456789^]/.test(identifier) == true) {
      errors["identifierempty"] = "Short Name must be alphabetical letters";
    } else if (identifier != '' && this.isLengthAllowed(identifier) == false) {
      errors["identifierempty"] = "Max length should be 128 characters";
    }

    if (officeAddress == "") {
      errors["officeAddressempty"] = "Please enter a office address.";
    } else if (officeAddress != '' && this.isHaveAlphabetic(officeAddress) == false) {
      errors["officeAddressempty"] = "Office Address must contain one alphabetical letter";
    } else if (officeAddress != '' && /[!$%*+\=\{}\[\];"\\|<>?~#@_^]/.test(officeAddress) == true) {
      errors["officeAddressempty"] = "Office Address must not contain special characters other than -/(),&.:'`";
    } else if (officeAddress != '' && this.isLengthAllowed(officeAddress) == false) {
      errors["officeAddressempty"] = "Max length should be 128 characters";
    }
    if (identifier !== '') {
      if (!identifier.match(/^[a-zA-Z]+$/)) {
        errors["identifierempty"] = "Please enter a valid short name. It allows alphabets without spaces";
      }
    }

    if (country == '') {
      errors["countryempty"] = "Please select the country.";
    }

    if (errors.hasOwnProperty("companyempty") || errors.hasOwnProperty("cityempty") || errors.hasOwnProperty("stateempty") || errors.hasOwnProperty("urlempty") || errors.hasOwnProperty("identifierempty") || errors.hasOwnProperty("officeAddressempty")) { }

    else {
      let url = baseUrl.URLPath + "admin/v1.0/account";
      //localhost:8080/cpms/chargepoints
      let data = { "company": company.toLowerCase(), "identifier": identifier, "officeAddress": officeAddress, "city": city, "state": state, "country": country, "url": orgurl };
      fetch(url, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(data), // data can be `string` or {object}!
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization"),
        }

      }).then(resp => {
        debugger
        if (resp.status == 401) {
          this.togglesession();
          // alert("Access token is expired, please login again")
          localStorage.clear();
        }
        else if (resp.status == 404) {
          this.setState({ errors: errors });

        } else if (resp.status == 500) {
          //  alert("internal server error")
        }
        else if (resp.status == 409) {
          // errors["chargepointexist"]="Charge Point already exists."
          this.setState({
            errors: errors
          });
        }
        else if (resp.status == 400) {
          return resp.json();
          //  alert("Bad request")
        } else {
          return resp.json();
        }
      }).then((response) => {
        if (response.status == "FAILURE") {

          if (response.errorMessage == "Organization already exists") {
            errors["shortnameempty"] = "Short name already exists.";
            $('.error_duplicate').text(response.errorMessage);
            this.setState({ errors: errors });
          } else {
            $('.error_duplicate').text("");
          }

        } else if (response.status == "SUCCESS") {
          if (response != undefined) {
            this.toggleSuccess()
          }
        } else {
          this.setState({
            validationErrors: response.messageArgs
          });
        }

      })
        .catch(error => alert('Error:' + error));
    }
    this.setState({ errors: errors });
  }
  countryChange() {

    var x = document.getElementById("country").value;
    let fields = this.state.fields;
    if (x != "Country") {

      this.state.errors["countryempty"] = " ";

    }
    fields["country"] = x;
    this.setState({ fields });


  }

  changeOrgAdd(field, e) {
    debugger
    var company = document.getElementById("companyId").value;
    var identifier = document.getElementById("identifierId").value;
    var officeAddress = document.getElementById("officeAddressIid").value;
    var city = document.getElementById("cityId").value;
    var state = document.getElementById("stateId").value;
    var url = document.getElementById("urlId").value;
    let field_value = e.target.value;
    field_value = field_value.replace(/\s+/g, " ");
    let fields = this.state.fields;
    fields[field] = field_value;

    this.setState({ fields });
    if (fields["companyId"] = company) {
      this.state.errors["companyempty"] = " ";
    }
    if (fields["identifierId"] = identifier) {
      this.state.errors["identifierempty"] = " ";
    }
    if (fields["officeAddressIid"] = officeAddress) {
      this.state.errors["officeAddressempty"] = " ";
    }
    if (fields["cityId"] = city) {
      this.state.errors["cityempty"] = " ";
    }
    if (fields["stateId"] = state) {
      this.state.errors["stateempty"] = " ";
    }
    if (fields["urlId"] = url) {
      this.state.errors["urlempty"] = " ";
    }

  }
  addRegestertoggle = () => {
    this.setState({
      modalRegester: !this.state.modalRegester
    });

  }
  organizationChange() {

    var x = document.getElementById("organisation").value;


    let fields = this.state.fields;
    fields["organisation"] = x;
    this.setState({ fields });
    if (x != 0) {

      this.state.errors["organisationempty"] = " ";

    }

  }
  onShowAlert() {
    debugger

    this.setState({ sucess1: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess1: false })
      }, 5000)
    });

  }






  async togglesession() {
    debugger;
    this.setState({
      modalsession: !this.state.modalsession
    });
  }


  sessiontagOk() {
    // this.props.history.push('/');
    history.push('/');
    window.location.reload();
  }

  tagOk() {
    this.props.history.push('/appRegestration');
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }




  addNew() {
    this.props.history.push('/Userlist');
  }

  backToList() {
    this.props.history.push('/appRegestration');
  }

  // checking the format of number
  numberHandler(field, e) {
    let fields = this.state;
    if (e.target.validity.valid) {
      fields[field] = e.target.value;
      this.setState({ fields });
    } else if (e.target.value == "") {
      fields[field] = e.target.value;
      this.setState({ fields });
    }
  }
  changeHandler(field, e) {
    var applicationName = document.getElementById("applicationName").value;
    var domain = document.getElementById("domain").value;
    var redirectUrl = document.getElementById("redirectUrl").value;
    var desc = document.getElementById("description").value;
    this.state.disabled = false;

    if (field == "employeeId") {
      $('.employeeidexists').text("");
    }
    let fields_value = e.target.value;
    let fields = this.state.fields;

    if (field == 'applicationName') {
      fields_value = fields_value.replace(/\s+/g, " ");
      fields[field] = fields_value;
      this.setState({ fields });
    } else {
      fields_value = fields_value.replace(/\s+/g, " ");
      fields[field] = fields_value;
      this.setState({ fields });
    }



    if (fields["applicationName"] = applicationName) {
      this.state.errors["emptyName"] = " ";
    }
    if (fields['domain'] = domain) {
      this.state.errors["emptyDomain"] = " ";
    }
    if (fields['redirectUrl'] = redirectUrl) {
      this.state.errors["emptyUrl"] = " ";
    }
  }


  activeChange = () => {
    this.state.disabled = false;

    var x = document.getElementById("isRedirection").checked;
    let isRedirection = this.state.isRedirection;
    if (x) {
      isRedirection = true;
    }
    else {
      isRedirection = false;
    }
    this.setState({ isRedirection });

  }
  componentDidMount() {

    var url = baseUrl.URLPath + "admin/v1.0/account/all";
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        debugger;
        if (resp.status == 401) {
          // alert("Access token is expired, please login again")
          this.togglesession();

          localStorage.clear();

        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        debugger
        if (response != undefined) {
          this.setState({ dataArray: response.organisationDTOS })
        }
        this.getRoles();
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
    if (this.props.location.state == null) {
      this.state.AppId = this.state.AppId
    } else {
      this.state.AppId = this.props.location.state;
    }

    this.getControllerApi();
    this.getApplicationDetails();

  }


  async getApplicationDetails() {
    var timeZone = localStorage.getItem("timezone");
    var url = baseUrl.URLPath + "application/" + this.state.AppId;
    await fetch(url, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        ;
        if (resp.status == 401) {
          this.togglesession();
          localStorage.clear();
        }
        if (resp.status == 500) {
          alert("internal server error")
        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        debugger
        let fields = {};
        let isRedirection = false;
        let list = [];

        if (response != undefined) {
          fields['applicationType'] = response.applicationType
          fields['applicationName'] = response.applicationName
          fields['description'] = response.desc
          fields['redirectUrl'] = response.redirectURL
          let apiIdList = [];
          // fields['organisation'] = response.redirectURL
          isRedirection = response.redirection
          fields.domain = response.domain
          fields.tenantId = response.tenantId
          response.accessedAPIs.map((controller, index) => {
            controller.apis.map(function (el) {
              apiIdList.push(el.apiId);
            });
          })

          this.state.controllerData.map((controllerall, index) => {
            response.accessedAPIs.map((controllerselected, index) => {
              if (controllerall.controller == controllerselected.controller && controllerall.apis.length == controllerselected.apis.length) {
                list.push(controllerall.controller.substring(0, controllerall.controller.indexOf(':')).trim())
              }
            });
          })

          let selectAll = false;
          if (list.length!=0 && list.length == this.state.controllerData.length) {
            selectAll = true
          }


          // let containsAllCompleted = trainingEpisodesList.every(c => completedEpisodes.find(e=>e.id==c.id));

          this.setState({
            fields: fields,
            list: list,
            selectAll:selectAll,
            isRedirection: isRedirection,
            controllerArray: apiIdList,
            selectedList: apiIdList,
            controllerDataAccess: response
          })
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  getControllerApi() {
    var url = baseUrl.URLPath + "controllers";
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((resp) => {
        debugger;
        if (resp.status == 401) {
          // alert("Access token is expired, please login again")
          this.togglesession();

          localStorage.clear();

        }
        if (resp.status == 200) {
          return resp.json();
        }
      })
      .then((response) => {
        debugger
        if (response != undefined) {
          this.setState({ controllerData: response })
        }
        this.getRoles();
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  isHaveAlphabetic(string) {
    if (/[a-zA-Z]/.test(string) == true) {
      return true;
    } else {
      return false;
    }
  }
  noSpecialAllowed(string) {
    if (/[`!$%&*()_+\-=\{};':"\\|,.<>\/?~]/.test(string) == true) {
      return true;
    } else {
      return false;
    }
  }
  noSpecialAllowedDesc(string) {
    if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|<>\/?~]/.test(string) == true) {
      return true;
    } else {
      return false;
    }
  }
  isLengthAllowed(string) {
    if (string.length <= 128) {
      return true;
    } else {
      return false;
    }
  }

  is_url(str) {
    let regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (regexp.test(str)) {
      return true;
    } else {
      return false;
    }
  }


  async handleSubmit(event) {
    debugger
    event.preventDefault();
    let errors = {};
    let apiIdList = [];
    this.state.controllerDataAccess.accessedAPIs.map((controller, index) => {
      controller.apis.map(function (el) {
        apiIdList.push(el.apiId);
      });
    })
    var newAPIs = this.state.controllerArray.filter(x => apiIdList.indexOf(x) === -1);
    var removedAPIsAccess = apiIdList.filter(x => this.state.controllerArray.indexOf(x) === -1);
    var applicationName = this.state.fields['applicationName'];
    var applicationType = this.state.fields['applicationType'];
    var domain = this.state.fields['domain'];
    var redirectUrl = this.state.fields['redirectUrl'];
    var organisation = this.state.fields['organisation'];
    var desc = this.state.fields['description'];


    if (applicationName == '') {
      errors["emptyName"] = "Please enter application name.";
    }
    if (applicationName != "" && applicationName != null && applicationName != undefined) {
      applicationName = applicationName.trim();
    }
    if (applicationName != '' && this.isHaveAlphabetic(applicationName) == false) {
      errors["emptyName"] = "Application Name must contain one alphabetical letter";
    } else if (applicationName != '' && /[`!$%&*()+\=\{}\[\];':"\\|,.<>\/?~]/.test(applicationName) == true) {
      errors["emptyName"] = "Application Name must not contain special characters";
    } else if (applicationName != '' && this.isLengthAllowed(applicationName) == false) {
      errors["emptyName"] = "Max length should be 128 characters";
    }
    if (applicationType == '') {
      errors["emptyAppType"] = "Please select application type.";
    }

    if (domain != "" && domain != null && domain != undefined) {
      domain = domain.toString();
      domain = domain.trim();
    }
    if (domain == '') {
      errors["emptyDomain"] = "Please enter domain or Ip Address.";
    }
    var domainArray = domain.split(',');
    if (domain != "") {
      for (var i = 0; i < domainArray.length; ++i) {
        if (this.validateURL(domainArray[i]) != true && this.validateIPAddress(domainArray[i]) != true) {
          errors["emptyDomain"] = "Please enter valid domain or ip Address";
        }
      }
    }

    if (redirectUrl != "" && redirectUrl != null && redirectUrl != undefined) {
      redirectUrl = redirectUrl.trim();
    }
    if (redirectUrl == '') {
      errors["emptyUrl"] = "Please enter redirect url.";
    } else if (this.validateURL(redirectUrl) != true) {
      errors["emptyUrl"] = "Please enter valid redirect url.";
    }
    if (organisation == '') {
      errors["organisationempty"] = "Please select organization.";
    }
    if (desc != "" && desc != null && desc != undefined) {
      desc = desc.trim();
      if (desc.length > 500) {
        errors["descriptionempty"] = "Description must not exceed 500 characters";
      }
    }


    if (errors.hasOwnProperty("descriptionempty") || errors.hasOwnProperty("emptyName") || errors.hasOwnProperty("organisationempty") || errors.hasOwnProperty("emptyAppType") || errors.hasOwnProperty("emptyDomain") || errors.hasOwnProperty("emptyUrl")) { }

    else {
      let url = baseUrl.URLPath + "application";
      //localhost/cpms/api/application/register
      let data = { "domain": domainArray, "status": true, "id": this.state.AppId, "removedAPIsAccess": removedAPIsAccess, "newAPIs": newAPIs, "redirectURL": redirectUrl, "tenantId": this.state.fields.organisation, "desc": this.state.fields.description, "redirection": this.state.isRedirection };


      fetch(url, {
        method: 'PUT', // or 'PUT'
        body: JSON.stringify(data), // data can be `string` or {object}!
        headers: {
          "content-type": "application/json",
          "Accept": "application/json",
          "Authorization": localStorage.getItem("Authorization"),
        }

      }).then(resp => {
        debugger
        if (resp.status == 401) {
          this.togglesession();
          // alert("Access token is expired, please login again")
          localStorage.clear();
        }
        else if (resp.status == 404) {
          this.setState({ errors: errors });

        } else if (resp.status == 500) {
        }

        else if (resp.status == 409) {
          return resp.json();
        }
        else if (resp.status == 400) {
          return resp.json();
        } else if (resp.status == 200) {
          return resp.blob();

        }
      }).then(response => {
        if (response.status == 409) {
          this.setState({
            applicationError: response.message
          });
          this.toggleFailure();
        } else if (response.status == 400) {
          this.setState({
            applicationError: response.message
          });
          this.toggleFailure();
        } else if (response.messageArgs != undefined) {
          this.setState({
            validationAppErrors: response.messageArgs
          });
        }
        else {
          this.setState({ getDownloadedText: response })
          this.toggle()
          this.setState({
            errors: errors
          });
        }
      })
      // .catch(error => alert('Error:' + "Internal Server Error"));
    }

    this.setState({ errors: errors });
  }
  downloadCsv() {
    let d = new Date();
    let dformat = `${d.getDate().toString().padStart(2, "0")}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d.getFullYear()}_${d.getHours()}-${d.getMinutes()}`;
    let url = window.URL.createObjectURL(this.state.getDownloadedText);
    let a = document.createElement('a');
    a.href = url;
    a.download = 'Regestered_Details_' + dformat + '.csv';
    a.click();
    this.toggle();
    this.props.history.push('/appRegestration');

  }
  applicationTypeChange() {
    var x = document.getElementById("applicationType").value;
    let fields = this.state.fields;
    fields["applicationType"] = x;
    this.setState({ fields });
    if (x != "Please select application type.") {
      this.state.errors["emptyAppType"] = " ";

    }

  }

  toggleCollapse(collapseID) {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }))
  }


  activeAllChange(id) {
    debugger

    this.state.disabled = false;

    var checkedValue = document.getElementById(id).checked;
    let selectAll = this.state.selectAll;
    if (checkedValue) {
      selectAll = true;
    }
    else {
      selectAll = false;
    }
    this.setState({ selectAll });

    let apiIdList = [];
    var list = [];
    this.state.controllerData.map((controller, index) => {
      list.push(controller.controller.substring(0, controller.controller.indexOf(':')).trim())
      controller.apis.map(function (el) {
        apiIdList.push(el.apiId);
      });
    })
    if (checkedValue == true) {
      this.setState({
        controllerArray: apiIdList,
        list: list
      })
    } else {
      debugger
      var ControllerId = this.state.controllerArray;
      apiIdList.map((apiId, index) => {
        let delElement = ControllerId.indexOf(apiId);
        if (index > -1) {
          ControllerId.splice(delElement, 1);
        }
      })
      list = [];
      this.setState({
        controllerArray: ControllerId,
        list: list
      }, () => {
      });
    }
  }

  chkhandleChange(contId, id, event) {
    // contId =  contId.substring(0, contId.indexOf(':')).trim()
    debugger
    this.state.disabled = false;
    var fdf = this.state.activeArray;
    var checkedValue = document.getElementById(id).checked;
    var apiIdList = [];
    var list = this.state.list;
    this.state.controllerData.map((controller, index) => {
      if (contId == controller.controller.substring(0, controller.controller.indexOf(':')).trim()) {
        apiIdList = controller.apis.map(function (el) { return el.apiId; });
      }
    })

    if (checkedValue == true) {
      list.push(contId);
      var myFinalArray = this.state.controllerArray.concat(apiIdList.filter((item) => this.state.controllerArray.indexOf(item) < 0));
      this.setState({
        controllerArray: myFinalArray,
        list: list
      })
    } else {
      var ControllerId = this.state.controllerArray;
      var list = this.state.list;

      apiIdList.map((apiId, index) => {
        let delElement = ControllerId.indexOf(apiId);
        if (index > -1) {
          ControllerId.splice(delElement, 1);
        }
      })
      // ControllerId.filter(clist => {
      //   return clist !== apiIdList;
      // }),8

      list.map((apiId, index) => {
        debugger
        let delElement2 = list.indexOf(contId);
        if (delElement2 > -1) {
          list.splice(delElement2, 1);
        }
      })
      this.setState({
        controllerArray: ControllerId,
        list: list
      });
    }
  }



  render() {
    const { collapseID } = this.state;
    let organisationDTOS = "";
    if (this.state.dataArray != undefined && this.state.dataArray != null && this.state.dataArray != "") {
      organisationDTOS = this.state.dataArray.map((dataArray, index) => {
        return (
          <option value={dataArray.identifier}>{dataArray.company}</option>
        )
      })
    }


    const controllerAccRow = this.state.controllerData.map((controller, index) => {
      let controller_name = controller.controller.substring(0, controller.controller.indexOf(':')).trim();

      return (
        <Card className="logs_row">
          <Accordion.Toggle as={Card.Header} eventKey={index} onClick={this.toggleCollapse.bind(this, `custom-${index}`)}>
            <div className="row">
              <div class="col-md-10">
                <label className="mb-0">
                  <Form.Check
                    custom
                    checked={this.state.list.find(elem => elem === controller_name) ? true : false}
                    onChange={this.chkhandleChange.bind(this, controller_name, controller_name)}
                    type="checkbox"
                    id={controller_name}
                    label=""
                    value={this.state.list.find(elem => elem === controller_name) ? true : false}
                  />
                </label>
                <label className='pt-1'>
                  {controller.controller}
                </label>
              </div>
              <div className="col-md-2 accordion_angle pt-1">
                <i className={collapseID === `custom-${index}` ? "fa fa-angle-up" : "fa fa-angle-down"} />
              </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={index} id={`custom-${index}`}>
            <Card.Body>
              <Table striped hover size="sm" className="page-table mt-0 mb-0">
                <tbody>
                  {
                    controller.apis.map((variable, i) => {
                      return (
                        <tr>
                          <td style={{ width: "6%" }}>
                            <Switch uncheckedIcon={false}
                              onColor="#00529e"
                              checkedIcon={false}
                              onChange={this.handleChange}
                              // id={`sck-${variable.apiId}`}
                              id={variable.apiId}
                              checked={this.state.controllerArray.find(elem => elem === variable.apiId) ? true : false}

                            />
                          </td>
                          <td>{variable.httpMethod}</td>
                          <td>{variable.api}</td>
                          <td>{variable.method}</td>
                          <td className='text-right p-1 vh-m'>{variable.methodName}</td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </Table>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      );
    })


    return (
      <>
        <main class="content-div" >
          <p> Edit Register Application
            <div className="breadcrumb_div">
              <Link to="/appRegestration">App Registration</Link > &gt; <span className="breadcrumb_page">Edit Register</span>
            </div>
          </p>
          <div className="page-outerdiv">
            {/* <div className="breadcrumb_div">Settings ><Link to="/userManagementController"> <span className="">Users > </span></Link>  Add User</div> */}
            <div className="row">
              <div className="col-md-12">
                <div className="pull-left">
                  <button type="button" data-test="button" className="btn_primary" onClick={this.backToList}><i class="fa fa-angle-left mr-2" aria-hidden="true"></i> Back</button>
                </div>
                <div className="pull-right ">
                  <button type="button" data-test="button" disabled={this.state.disabled} onClick={this.handleSubmit} className={this.state.disabled == true ? "btn_primary mr-3 btn_cancel" : "btn_primary mr-3 "} > Update <i class="fas fa-save ml-2"></i></button>

                  {/* <button onClick={this.addRegestertoggle} type="button" data-test="button">
                    <i className="fa fa-plus">
                    </i>
                    <span>Add Organization</span>
                  </button> */}
                </div>
              </div>
            </div>
            <MDBRow className="mb-4">
              <MDBCol sm="12">
                <div>
                  <form
                    className="needs-validation"
                    onSubmit={this.submitHandler}
                    noValidate
                  >
                    <MDBRow>

                      <div className="col-md-12 mb-3">

                        <span class=" form-title">Registration Details:</span>

                      </div>
                      <MDBCol md="4" >
                        <select disabled className="browser-default custom-select select_height mb_8" id="organisation" onChange={this.organizationChange.bind(this)} value={this.state.fields.tenantId} >
                          <option value="">-- Select Organization*--</option>
                          {organisationDTOS}
                        </select>
                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["organisationempty"]}</span></span>
                      </MDBCol>
                      <MDBCol md="4" >
                        <select disabled className="browser-default custom-select select_height mb_8" id="applicationType" value={this.state.fields.applicationType} onChange={this.applicationTypeChange
                          .bind(this)}  >
                          <option value="">-- Select Application Type*--</option>
                          <option value="Cloud-to-Cloud">Cloud-to-Cloud</option>
                          {/* <option value="Web Application">Web Application</option>
                          <option value="Mobile Application">Mobile Application</option> */}
                        </select>
                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["emptyAppType"]}</span></span>
                      </MDBCol>

                      <div className="col-md-4 md-form">
                        <input name="applicationName" disabled type="text" id="applicationName" className="form-control" onChange={this.changeHandler.bind(this, "applicationName")} value={this.state.fields["applicationName"]} />
                        <label htmlFor="applicationName" className={`${(this.state.fields['applicationName'] != "" && this.state.fields['applicationName'] != null) ? "active" : ""} ml-15`}>Application Name*</label>
                        <div className="invalid-feedback">Please provide a valid Email.</div>
                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["emptyName"]}</span></span>
                      </div>
                      <div className="col-md-4 md-form">
                        <input name="domain" type="text" id="domain" className="form-control" onChange={this.changeHandler.bind(this, "domain")} value={this.state.fields["domain"]} />
                        <label htmlFor="domain" className={`${(this.state.fields['domain'] != "" && this.state.fields['domain'] != null) ? "active" : ""} ml-15`}>Domain or IP*</label>
                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["emptyDomain"]}</span></span>
                      </div>
                      <div className="col-md-4 md-form">
                        <input name="description" type="text" id="description" className="form-control" onChange={this.changeHandler.bind(this, "description")} value={this.state.fields["description"]} />
                        <label htmlFor="description" className={`${(this.state.fields['description'] != "" && this.state.fields['description'] != null) ? "active" : ""} ml-15`}>Description (Optional)</label>
                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["descriptionempty"]}</span></span>
                      </div>
                      <div className="col-md-4 md-form">
                        <input name="redirectUrl" type="text" id="redirectUrl" className="form-control" onChange={this.changeHandler.bind(this, "redirectUrl")} value={this.state.fields["redirectUrl"]} />
                        <label htmlFor="redirectUrl" className={`${(this.state.fields['redirectUrl'] != "" && this.state.fields['redirectUrl'] != null) ? "active" : ""} ml-15`}>Redirect URL*</label>
                        <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["emptyUrl"]}</span></span>
                      </div>
                      <div className="col-md-4">
                        <Form.Check
                          custom
                          value={this.state.isRedirection}
                          onChange={this.activeChange}
                          type="checkbox"
                          id="isRedirection"
                          label="Is Redirection"
                          checked={this.state.isRedirection}

                        />
                      </div>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="12" className="mt-3" >
                        <div className="cp-bg p-4">
                          <MDBRow>
                            <MDBCol md="6"  >
                              <b>Select Controllers</b>
                            </MDBCol>
                            <MDBCol md="6" className="text-right"  >
                              <Form.Check
                                custom
                                value={this.state.selectAll}
                                onChange={this.activeAllChange.bind(this, "accessToallApis")}
                                type="checkbox"
                                id="accessToallApis"
                                label="Access to all APIs "
                                checked={this.state.selectAll}

                              />
                            </MDBCol>
                            <MDBCol md="12">
                              <Accordion defaultActiveKey="0">
                                {controllerAccRow}
                              </Accordion>
                            </MDBCol>
                          </MDBRow>
                        </div>
                      </MDBCol>

                    </MDBRow>

                    <MDBRow>
                      <div className="error_msg w3-animate-top mt-2" style={{ color: "red" }}>
                        {
                          this.state.validationAppErrors.map((validation) => {
                            return <div>
                              {validation}
                            </div>
                          })
                        }
                      </div>
                    </MDBRow>
                    {/* <button type="button" data-test="button" className="mt-5 btn_primary mr-3" onClick={this.handleSubmit}> Update <i class="fas fa-save ml-2"></i></button> */}
                  </form>

                </div>
              </MDBCol>
            </MDBRow>
          </div>

        </main>
        <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggle}>Success</MDBModalHeader>
          <MDBModalBody>
            Application updated successfully.
          </MDBModalBody>
          <MDBModalFooter>
            <button className="btn_primary" data-test="button" type="button" onClick={this.tagOk}>OK</button>
          </MDBModalFooter>
        </MDBModal>

        {/* popup for session expire */}

        <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
          <MDBModalHeader toggle={this.togglesession}>Session has expired</MDBModalHeader>
          <MDBModalBody>
            Please login again.
          </MDBModalBody>
          <MDBModalFooter>
            {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
            <button className="btn_primary" data-test="button" type="button" onClick={this.sessiontagOk}>OK</button>
          </MDBModalFooter>
        </MDBModal>


        {/* Add organisation */}
        <MDBModal isOpen={this.state.modalRegester} toggle={this.addRegestertoggle} className="custom_model_w" size="lg" backdrop={true} wrapClassName="custom_backdropclass">
          <div data-test="modal-header" class="modal-header">
            <div class="modal-title fw-800 mt-1" ref={(node) => { if (node) { node.style.setProperty("font-size", "18px", "important"); } }}>Add Organization</div>
            <button type="button" data-test="button" class="close" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          {/* <MDBModalHeader toggle={this.toggle}>Please insert your data</MDBModalHeader> */}
          {/* {this.state.showLoder=="true" ? <Loader loader={this.state.showLoder}/> : "" } */}

          <MDBModalBody>
            <form
              className="needs-validation"
              onSubmit={this.submitHandler}
              noValidate
            >
              <div className="row" style={{ marginTop: '0.05rem' }}>
                <MDBCol md="5" >
                  <label className="pull-left sel_lbl">Organization :</label>
                </MDBCol>
                <MDBCol md="5" >
                  <MDBInput
                    name="company"
                    value={this.state.fields["company"]}
                    onChange={this.changeOrgAdd.bind(this, "company")}
                    type="text"
                    id="companyId"
                    // label="Organization"
                    autoComplete="off"
                    className="text_lowercase"
                  >
                    <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["companyempty"]}</span></span>
                  </MDBInput>

                </MDBCol>
              </div>
              <div className="row" style={{ marginTop: '0.05rem' }}>
                <MDBCol md="5" >
                  <label className="pull-left sel_lbl">Short Name :</label>
                </MDBCol>
                <MDBCol md="5">
                  <MDBInput
                    name="identifier"
                    value={this.state.fields["identifier"]}
                    onChange={this.changeOrgAdd.bind(this, "identifier")}
                    type="text"
                    id="identifierId"
                    // label="Short Name"
                    autoComplete="off"
                  >
                    <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["identifierempty"]}</span></span>
                    <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}></span></span>

                  </MDBInput>

                </MDBCol>
              </div>
              {/* <div className="row" style={{ marginTop: '0.05rem' }}>
                <MDBCol md="12">
                  <label className="pull-left sel_lbl"><b>OEM Credentials:</b></label>
                </MDBCol>
              </div> */}
              <div className="row" style={{ marginTop: '0.05rem' }}>
                <MDBCol md="5" >
                  <label className="pull-left sel_lbl">Office Address:</label>
                </MDBCol>
                <MDBCol md="5" >
                  <MDBInput
                    name="officeAddress"
                    value={this.state.fields["officeAddress"]}
                    onChange={this.changeOrgAdd.bind(this, "officeAddress")}
                    min="0"
                    id="officeAddressIid"
                    autoComplete="off"
                  >
                    <span className="error_msg w3-animate-top">  <span style={{ color: "red" }}>{this.state.errors["officeAddressempty"]}</span></span>
                  </MDBInput>
                </MDBCol>
              </div>
              <div className="row" style={{ marginTop: '0.05rem' }}>

                <MDBCol md="5">
                  <label className="pull-left sel_lbl">City:</label>
                </MDBCol>
                <MDBCol md="5">
                  <MDBInput
                    value={this.state.fields["city"]}
                    onChange={this.changeOrgAdd.bind(this, "city")}
                    type="text"
                    id="cityId"
                    name="city"
                  >
                    <span className="error_msg w3-animate-top"><span style={{ color: "red" }}>{this.state.errors["cityempty"]}</span></span>
                  </MDBInput>

                </MDBCol>
              </div>
              <div className="row" style={{ marginTop: '0.05rem' }}>

                <MDBCol md="5">
                  <label className="pull-left sel_lbl">State :</label>
                </MDBCol>
                <MDBCol md="5">
                  <MDBInput
                    value={this.state.fields["state"]}
                    onChange={this.changeOrgAdd.bind(this, "state")}
                    onInput={this.numberHandler.bind(this, "state")}
                    type="text"
                    id="stateId"
                    name="state"
                  >
                    <span className="error_msg w3-animate-top"><span style={{ color: "red" }}>{this.state.errors["stateempty"]}</span></span>
                  </MDBInput>
                </MDBCol>
              </div>
              <div className="row" style={{ marginTop: '0.05rem' }}>

                <MDBCol md="5">
                  <label className="pull-left sel_lbl">Country:</label>
                </MDBCol>
                <MDBCol md="5">
                  <select className="browser-default custom-select select_height" id="country" onChange={this.countryChange.bind(this)}>
                    <option value="Country">Country</option>
                    <option value="Afghanistan">Afghanistan</option>
                    <option value="Aland Islands">Aland Islands</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="American Samoa">American Samoa</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Angola">Angola</option>
                    <option value="Anguilla">Anguilla</option>
                    <option value="Antarctica">Antarctica</option>
                    <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Aruba">Aruba</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bermuda">Bermuda</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                    <option value="Botswana">Botswana</option>
                    <option value="Bouvet Island">Bouvet Island</option>
                    <option value="Brazil">Brazil</option>
                    <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                    <option value="Brunei Darussalam">Brunei Darussalam</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Cameroon">Cameroon</option>
                    <option value="Canada">Canada</option>
                    <option value="Cape Verde">Cape Verde</option>
                    <option value="Cayman Islands">Cayman Islands</option>
                    <option value="Central African Republic">Central African Republic</option>
                    <option value="Chad">Chad</option>
                    <option value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="Christmas Island">Christmas Island</option>
                    <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comoros">Comoros</option>
                    <option value="Congo">Congo</option>
                    <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                    <option value="Cook Islands">Cook Islands</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Cote D'ivoire">Cote D'ivoire</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czech Republic">Czech Republic</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="Dominican Republic">Dominican Republic</option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                    <option value="Faroe Islands">Faroe Islands</option>
                    <option value="Fiji">Fiji</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="French Guiana">French Guiana</option>
                    <option value="French Polynesia">French Polynesia</option>
                    <option value="French Southern Territories">French Southern Territories</option>
                    <option value="Gabon">Gabon</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Gibraltar">Gibraltar</option>
                    <option value="Greece">Greece</option>
                    <option value="Greenland">Greenland</option>
                    <option value="Grenada">Grenada</option>
                    <option value="Guadeloupe">Guadeloupe</option>
                    <option value="Guam">Guam</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guernsey">Guernsey</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guinea-bissau">Guinea-bissau</option>
                    <option value="Guyana">Guyana</option>
                    <option value="Haiti">Haiti</option>
                    <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                    <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                    <option value="Honduras">Honduras</option>
                    <option value="Hong Kong">Hong Kong</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="India">India</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                    <option value="Iraq">Iraq</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Isle of Man">Isle of Man</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japan">Japan</option>
                    <option value="Jersey">Jersey</option>
                    <option value="Jordan">Jordan</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                    <option value="Korea, Republic of">Korea, Republic of</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                    <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lebanon">Lebanon</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Macao">Macao</option>
                    <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Maldives">Maldives</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Marshall Islands">Marshall Islands</option>
                    <option value="Martinique">Martinique</option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Mayotte">Mayotte</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                    <option value="Moldova, Republic of">Moldova, Republic of</option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Montenegro">Montenegro</option>
                    <option value="Montserrat">Montserrat</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Myanmar">Myanmar</option>
                    <option value="Namibia">Namibia</option>
                    <option value="Nauru">Nauru</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Netherlands">Netherlands</option>
                    <option value="Netherlands Antilles">Netherlands Antilles</option>
                    <option value="New Caledonia">New Caledonia</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Niue">Niue</option>
                    <option value="Norfolk Island">Norfolk Island</option>
                    <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                    <option value="Norway">Norway</option>
                    <option value="Oman">Oman</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Palau">Palau</option>
                    <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                    <option value="Panama">Panama</option>
                    <option value="Papua New Guinea">Papua New Guinea</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Peru">Peru</option>
                    <option value="Philippines">Philippines</option>
                    <option value="Pitcairn">Pitcairn</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Reunion">Reunion</option>
                    <option value="Romania">Romania</option>
                    <option value="Russian Federation">Russian Federation</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="Saint Helena">Saint Helena</option>
                    <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                    <option value="Saint Lucia">Saint Lucia</option>
                    <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                    <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                    <option value="Samoa">Samoa</option>
                    <option value="San Marino">San Marino</option>
                    <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Serbia">Serbia</option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="Sierra Leone">Sierra Leone</option>
                    <option value="Singapore">Singapore</option>
                    <option value="Slovakia">Slovakia</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Solomon Islands">Solomon Islands</option>
                    <option value="Somalia">Somalia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                    <option value="Spain">Spain</option>
                    <option value="Sri Lanka">Sri Lanka</option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                    <option value="Swaziland">Swaziland</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                    <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                    <option value="Tajikistan">Tajikistan</option>
                    <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                    <option value="Thailand">Thailand</option>
                    <option value="Timor-leste">Timor-leste</option>
                    <option value="Togo">Togo</option>
                    <option value="Tokelau">Tokelau</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Turkmenistan">Turkmenistan</option>
                    <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                    <option value="Tuvalu">Tuvalu</option>
                    <option value="Uganda">Uganda</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="United Arab Emirates">United Arab Emirates</option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="United States">United States</option>
                    <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                    <option value="Uruguay">Uruguay</option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Viet Nam">Viet Nam</option>
                    <option value="Virgin Islands, British">Virgin Islands, British</option>
                    <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                    <option value="Wallis and Futuna">Wallis and Futuna</option>
                    <option value="Western Sahara">Western Sahara</option>
                    <option value="Yemen">Yemen</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                  </select>
                  <span className="error_msg w3-animate-top mt-1">  <span style={{ color: "red" }}>{this.state.errors["countryempty"]}</span></span>
                </MDBCol>
              </div>
              <div className="row" style={{ marginTop: '0.05rem' }}>

                <MDBCol md="5">
                  <label className="pull-left sel_lbl">Url :</label>
                </MDBCol>
                <MDBCol md="5">
                  <MDBInput
                    value={this.state.fields["url"]}
                    onChange={this.changeOrgAdd.bind(this, "url")}
                    onInput={this.numberHandler.bind(this, "url")}
                    type="text"
                    id="urlId"
                    name="url"
                  >
                    <span className="error_msg w3-animate-top"><span style={{ color: "red" }}>{this.state.errors["urlempty"]}</span></span>
                  </MDBInput>
                </MDBCol>
              </div>

              <div className="row" style={{ marginTop: '0.05rem' }}>
                <div className="error_msg w3-animate-top error_duplicate" style={{ color: "red" }}>
                  {
                    this.state.validationErrors.map((validation, index) => {
                      return (
                        <div>{validation}</div>
                      )
                    })
                  }
                </div>
              </div>
            </form>
          </MDBModalBody>
          <MDBModalFooter className="border-top-0 p-3">
            <button type="button" data-test="button" className="btn_cancel" onClick={this.closetoggle}> Cancel <i class="fas fa-times ml-2"></i></button>
            <button type="button" data-test="button" className="btn_primary" onClick={this.addOrganizationSubmit}> Add Organization <i class="fas fa-plus ml-2"></i></button>
            {/* <MDBBtn color="danger" onClick={this.closetoggle}>Cancel</MDBBtn>
 <MDBBtn color="primary" onClick={this.handleSubmit}>Add Vehicle</MDBBtn> */}
          </MDBModalFooter>
        </MDBModal>

        {/* success toggle */}
        <MDBModal isOpen={this.state.modalSuccess} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggleSuccess}>Success</MDBModalHeader>
          <MDBModalBody>
            Organization added successfully.
          </MDBModalBody>
          <MDBModalFooter>
            {/* <MDBBtn color="secondary" onClick={this.toggle}>Close</MDBBtn> */}
            <MDBBtn color="primary" onClick={this.successOk.bind(this)}>OK</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        <MDBModal isOpen={this.state.applicationFailure} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggleFailure}>Error</MDBModalHeader>
          <MDBModalBody>
            {this.state.applicationError}
          </MDBModalBody>
          <MDBModalFooter>
            {/* <MDBBtn color="secondary" onClick={this.toggle}>Close</MDBBtn> */}
            <button type="button" className="btn_primary" onClick={this.toggleFailure}>OK</button>
          </MDBModalFooter>
        </MDBModal>
      </>
    );
  }
}

export default editRegestration;