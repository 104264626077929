import React from 'react';
import { MDBInput, MDBCol,MDBRow, MDBModal , MDBBtn, MDBModalHeader,MDBModalBody,MDBModalFooter} from "mdbreact";
import {Form,Table,Check } from 'react-bootstrap';
import * as baseUrl from './constants';
import {Link} from 'react-router-dom';
import { Alert } from 'reactstrap';
import $, { get } from "jquery";
import Header from './header';
import { createBrowserHistory } from 'history';
import { hasRole, hasPermission } from './auth';
import Pagination from "react-js-pagination";
import './cpDashboard.css';

const history = createBrowserHistory();

class userManagementController extends React.Component {  
    constructor (props) {
        super(props);
        this.state = {
          fields:{
            emailId:"",
          },
          errors: {
            selectChargepoint:"",
            notexists:"",


          },
          noOfRecords:'',
          data:new Map(),
          pageSize:"10",
          activePage: 1,
          pageNo:1,
          delete_msg:"",
          deleteHeader:"",
          deleteButton:"",
          deleteText:"",
          deletecolor:"danger",
          actiondisplay:"",
          activeArray: [],
          checked:false,
          activeDeactiveArray:'',
          list: [],
          page_title:"Users",
          sucess:false,
          userArray: [
            {
            
              firstName: '',
              lastName:'',
              email:'',
              mobileNumber:'',
              role:'',
              status:'',
               active:'',
            
          }
          ],
          model1:"false" ,                  
          model2:"false",
          id:"",
          dataArray:[],
          org_name:'',
          modalsession:false,
        
          user:{
            permissions: [
                
            ],
        }
      }
      this.addNew = this.addNew.bind(this)
      this.closeModel = this.closeModel.bind(this)
      this.getUserslist = this.getUserslist.bind(this)
      this.activeUsers = this.activeUsers.bind(this)
      this.handleChange = this.handleChange.bind(this)
      this.toggle1 = this.toggle1.bind(this)
      this.toggle2 = this.toggle2.bind(this)
      this.tagOk2 = this.tagOk2.bind(this)
      this.tagOk1 = this.tagOk1.bind(this)
      this.deleteUser = this.deleteUser.bind(this)
      this.toggleConfirmation = this.toggleConfirmation.bind(this)
      this.closeOkPopup = this.closeOkPopup.bind(this)
      this.toggle = this.toggle.bind(this)
      this.togglesession = this.togglesession.bind(this)
      this.sessiontagOk = this.sessiontagOk.bind(this)
      this.getNonWebastoUsers=this.getNonWebastoUsers.bind(this);
      this.goLoginHistory=this.goLoginHistory.bind(this);
      
      this.onChangeEmail=this.onChangeEmail.bind(this);
      this.changeHandler = this.changeHandler.bind(this);
      this.handleClick = this.handleClick.bind(this);
      this.Focus = this.Focus.bind(this);
      this.onChangeOrg = this.onChangeOrg.bind(this);
      this.handlePageChange = this.handlePageChange.bind(this);
      this.showEntriesChange=this.showEntriesChange.bind(this);
      this.keyPress = this.keyPress.bind(this);






      }
      keyPress(e){
    
        var key = e.key;
        if(e.keyCode == 13){
        
            this.setState({ emailId: e.target.value });
             this.getUserslist(this.state.id,this.state.pageSize,1);
             if(this.state.fields.emailId == ""){
              this.state.errors["selectChargepoint"] = "Please enter email id"
              $("#notexists").text(" ");
            
            }else{
              this.state.errors["selectChargepoint"] = " "
              this.getUserslist(this.state.id,this.state.pageSize,1,"enter");
            }
            
          }
      
          if( key == "Backspace" || key == "Delete" ){
       
            var x=e.target.value
            var sno=x.slice(0,x.length-1)
            if(sno==""){
         
              this.state.fields.emailId=sno;
        
             this.getUserslist(this.state.id,this.state.pageSize,1);
            }
            }
            switch (e.keyCode) {
              case 8:  // Backspace
              case 9:  // Tab
              case 13: // Enter
              case 37: // Left
              case 38: // Up
              case 39: // Right
              case 40: // Down
              break;
              default:
                var regex = new RegExp("^[a-zA-Z0-9.@-]");
              var key = e.key;
              if (!regex.test(key)) {
              
                  e.preventDefault();
          
                  return false;
               
                 
              }
              break;
            
          }
      }
      showEntriesChange(){
        var entries=document.getElementById("showEntries").value;
        // this.state.data.clear();
        // this.setState({pageSize:entries});
        this.state.pageSize=entries;
        this.state.activePage=1;
        this.getUserslist(this.state.id,entries,1);
      }
      handlePageChange(pageNumber) {
    
      
        if(pageNumber !=this.state.activePage){
          var data=this.state.data;
          var pageData=data.get(pageNumber);
          if(pageData==undefined){
        this.setState({activePage: pageNumber});
        this.getUserslist(this.state.id,this.state.pageSize,pageNumber);
          }else{
            this.setState({activePage: pageNumber});
          }
        }
      }
      handleClick(){
       
       
        let fields = this.state.fields;
        fields.emailId='';
        this.state.errors["selectChargepoint"] = " "
        $("#notexists").text(" ");
        this.setState({
      fields
        });
        this.state.pageSize=10;
        this.state.activePage=1;
        document.getElementById("showEntries").value=10;
        this.getUserslist(this.state.id,this.state.pageSize,this.state.activePage);
    
      }
     Focus(){
      
       
      //   let fields = this.state.fields;
      //   fields.emailId='';
    
      //   this.setState({
      // fields
      //   });
      //   this.getUserslist(this.state.id,this.state.pageSize,1);
    
       }
      changeHandler(field, e){ 

        let fields = this.state.fields;
        fields[field] = e.target.value; 
        fields.emailid = document.getElementById('emailId')
        this.state.errors["selectChargepoint"] = "Please enter email id"
        this.setState({fields});
        this.state.errors["selectChargepoint"] = ""
        $("#notexists").text(" ");
        }
      
       //change function for user  filter
       onChangeEmail() {
         
    var email = document.getElementById("emailId").value;
    this.setState({ emailId: email }, () => {
        this.getUserslist(this.state.id,this.state.pageSize,1);
      });
      if(this.state.fields.emailId == ""){
        this.state.errors["selectChargepoint"] = "Please enter email id"
        $("#notexists").text(" ");
      
      }else{
        this.state.errors["selectChargepoint"] = " "
        this.getUserslist(this.state.id,this.state.pageSize,1,"click");
      }
  }
 onChangeOrg(){
  this.getUserslist(this.state.id,this.state.pageSize,1);

 }

      async goLoginHistory(){
        this.props.history.push('/userLoginHistory');
      }

      async togglesession(){
     
        this.setState({
        modalsession: !this.state.modalsession
        });
      }
      
      
      sessiontagOk(){
        // this.props.history.push('/');
        history.push('/');
        window.location.reload();
      }
      
toggle = () => {
  this.setState({
    modal: !this.state.modal
  });
  
}




toggleConfirmation(event){
  
  this.setState({
      modal1: !this.state.modal1
    });
   // event.preventDefault();
if(this.state.list[0]!="" && this.state.list[0]!=undefined){


 
  this.setState({
    delete_msg:"Are you sure you want to delete?",
    deleteHeader:"Confirmation",
    deleteButton:"",
    deleteText:"Close",
    deletecolor:"danger"
})
}else{

  this.setState({
      delete_msg:"Please select the User.",
      deleteHeader:"Warning",
      deleteButton:"d-none",
      deleteText:"Ok",
      deletecolor:"primary"
  })
}

}


closeOkPopup(){
  this.setState({
    modal: !this.state.modal
  });
  // this.props.history.push('/userManagementController');
  window.location.reload();
}


tagOk1(){
  window.location.reload();
}
tagOk2(){
  window.location.reload();
 }

toggle1(){
  this.setState({
    modal3: !this.state.modal3
  });
}
toggle2 = () => {
  this.setState({
    modal2: !this.state.modal2
  });
}

closeModel(){
  window.location.reload();
}




 addNew(){
  this.props.history.push('/addUserManagement');
}
// calling function when click on checkbox
handleChange(id,identifier,event){
 
  let arr = [];
  var newlist = [];
  var checkedValue = document.getElementById(id).checked;
    if(checkedValue==true){
    if(this.state.list[0]!="" && this.state.list[0]!= undefined ){
      document.getElementById(this.state.list[0]).checked = false;
      document.getElementById(id).checked = true;
    }
    this.state.list[0] = id ; 
    this.setState({
      activeArray:identifier
      });
  }
  if(checkedValue==false){
    this.state.list[0] = "" ; 
    document.getElementById(id).checked = false;
    this.setState({
      activeArray:""
    });
  }
    this.state.activeDeactiveArray = identifier;
  }
getUserslist(id1,pageSize,pageNo,action){
  
  if(id!==""){
    id = this.state.id;
  }else{
    id = id1;
  }
  
  this.setState({id: id})
  var id = document.getElementById("organizationid").value;

  var el = document.getElementById('organizationid')
  var  selectedText = el.options[el.selectedIndex].text
  this.setState({
    org_name:selectedText
  })
  
  var url =  baseUrl.URLPath + "v1.0/tenant/search?pageSize="+pageSize+"&pageNo="+pageNo+"&email="+this.state.fields.emailId+"&id="+id;
  //localhost:80/cpms/api/v1.0/tenant/search?pageSize=10&pageNo=1&email=ocppadmin@webasto.com&id=6040cff7b48bcd2e04eb8cac
  fetch(url,{
      method: "GET",
        headers:{
          "content-type":"application/json",
          "Authorization":localStorage.getItem("Authorization"),
        }
      })
      .then((resp) => {
   
        if(resp.status==401){
          this.togglesession();
    
          localStorage.clear();
         
        }
        if(resp.status==200){
          return resp.json();
        }
      }) 
      .then((response) => {
      
        if(response!=undefined){
         
            if(response.count.count==0){
              if(action=="click" || action=="enter"){ 
                $("#notexists").text("Email id not exists.");
                                }
    
            }else{
        this.setState({
           userArray: response.userList,
           noOfRecords:response.count.count

          })
          if(action=="click" || action=="enter"){ 
            $("#notexists").text(" ");
                            }
        }
      }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
      }

      activeUsers(status){
       
      if(this.state.activeArray!="" && this.state.activeArray.length != "0" ){
        if(status==true){
         var url = baseUrl.URLPath + "v1.0/tenant/activate/"+this.state.activeArray;
        }else{
         var url = baseUrl.URLPath + "v1.0/tenant/deactivate/"+this.state.activeArray;
        }
       // let data = {"id":this.state.activeArray, "active":status}; 
       fetch(url,{
          method: "GET",
          //body: JSON.stringify(data), // data can be `string` or {object}!
          headers: {
           "content-type":"application/json",
           "Authorization":localStorage.getItem("Authorization")
          }
          })
          .then((resp) => {
         
            return resp.json();
          }) 
          .then((response) =>{
           if(status==0){
               this.toggle1()
            
            }else{
              this.toggle2()
            }
         })
         .catch((error) => {
              console.log(error, "catch the loop")
        })
        }else if(this.state.activeArray=="" && this.state.activeArray.length == "0" ){
          this.toggleConfirmation();
        }
     }

      componentDidMount(){
     
        this.state.user.permissions=localStorage.getItem("roleAccess");
        var orgname  = localStorage.getItem("tenant");
        this.setState({
            org_name:orgname
        })
        var url = baseUrl.URLPath + "admin/v1.0/account/all";
        fetch(url,{
          method: "GET",
          headers: {
                "content-type":"application/json",
                "Authorization":localStorage.getItem("Authorization")
              }
          })
          .then((resp) => {
         
            if(resp.status==401){
              this.togglesession();
        
              localStorage.clear();
             
            }
            if(resp.status==200){
              return resp.json();
            }
          })  
          .then((response) =>{
         
            if(response!=undefined){
            this.setState({ dataArray:response.organisationDTOS })
            var user_data = []; 
            user_data = response.organisationDTOS;
             var first_org = "";
            for(var i=0; i<=user_data.length; i++){
              first_org = user_data[0].id; 
            }
            this.getUserslist(first_org,this.state.pageSize,this.state.activePage);
          }else{
          
            this.getNonWebastoUsers();
         }
          })
          .catch((error) => {
              console.log(error, "catch the loop")
          })
      }


      getNonWebastoUsers(){
        var url =  baseUrl.URLPath + "v1.0/tenant/Nonwebasto";
        fetch(url,{
            method: "GET",
              headers:{
                "content-type":"application/json",
                "Authorization":localStorage.getItem("Authorization"),
              }
            })
            .then((resp) => {
         
              if(resp.status==401){
                this.togglesession();
          
                localStorage.clear();
               
              }
              if(resp.status==200){
                return resp.json();
              }
            }) 
            .then((response) => {
            
              if(response!=undefined){
              this.setState({ userArray: response.userDTOS});
             // this.state.userArray=response.userDTOS;
              }
            })
            .catch((error) => {
              console.log(error, "catch the loop")
            })
          
      }
      deleteUser(){
     
  
       this.setState({
         modal1: !this.state.modal1
       });
      var id;
      id = this.state.id;
      this.setState({id: id})
      var url =  baseUrl.URLPath + "v1.0/tenant/"+this.state.activeArray;
      // DELETE /api/v1.0/tenant/{id}
       fetch(url, 
             {
               method: 'DELETE', // or 'PUT'
              //  body: JSON.stringify("NULL"), // data can be `string` or {object}!
               headers:{
                     'Content-Type': 'application/json',
                     "Authorization":localStorage.getItem("Authorization"),
                   }
             }
           )
           .then(res=>res.json())
           .then(resp =>{
           
             if(resp.status==401){
              this.togglesession();
              // alert("Access token is expired, please login again")
              localStorage.clear();
            }
               else if(resp.status==404){
                
                 return resp.json();
               }
               else if(resp.status==500){
                 alert("internal server error")
               } else if(resp.status==400){
                 alert("Bad request")
               }else{
                 this.setState({
                   modal: !this.state.modal
                 });
                // this.props.history.push('/organisationList');
                 // $("#suc_msg").html(' <strong>Success!</strong>Charge Point Tag Id deleted successfully.').slideDown();
                 // setTimeout(this.redirectToListPage, 3000);
               }
         })
          .catch(error => alert('Error:' + error));
    
     }
   

render() {

 
  if(localStorage.getItem("role") == "WEBASTO_SUPER_ADMIN_DEVOPS"){
    this.state.actiondisplay = "none";
   
  }
  else{
    this.state.actiondisplay = "display:block";
 
  }

 


let userTenant = localStorage.getItem("tenant");
const user = this.state.user;
let selectedId = "";
const organisationDTOS =this.state.dataArray.map((dataArray, index) => {

if(userTenant === dataArray.identifier){
selectedId = dataArray.id
}
return(
<option value={dataArray.id} selected={selectedId == dataArray.id} >{dataArray.company}</option>
)
})

  const userDTOS =this.state.userArray.map((userArray, index) => {
    
        
    // logic for getting rfids by comma saparated
var dictRfids = userArray.rfids;
var getRfids = [];

if(dictRfids != undefined){
  for(var i=0; i<dictRfids.length; i++){
 getRfids.push(dictRfids[i]["rfidTag"]);
   }
  }
var commaSepArray = getRfids.join(", ");

 




    //  if(userArray.status!=""){
    if(userArray.status==true){
      userArray.status = "Active";
    }else{
      if(userArray.status==false){
        userArray.status = "Inactive";
  
      }
    }



  
    
    
    
  
      return(
        
        <tr>
           <td  className="text-center"> 
           <Form.Check 
                custom
                type="checkbox"
                id={`custom-${userArray.id}`}
                label = ""
                onChange = {this.handleChange.bind(this,`custom-${userArray.id}`,userArray.id)}
                key={userArray.id}
                className="checkbox"
              />
          </td>
          <td>{userArray.id}</td>
       
         <td>{userArray.email}</td>
         {/* <td> {commaSepArray}</td> */}
         {/* {hasPermission(user, ['UserDetail_R']) && 
        <td>{userArray.firstName}</td>}
        {hasPermission(user, ['UserDetail_R']) && 
        <td>{userArray.lastName}</td> } */}
        {/* <td>{userArray.mobileNumber}</td> */}
        <td>{userArray.role}</td>
        <td>{userArray.status}</td>
        {/* <td style={{display:this.state.actiondisplay}}> <Link to={{
              pathname: '/editUser',
              state: userArray.id,
            }}  ><i class="fas fa-pencil-alt   pr-2 action-icon edit_usericon" title="Edit"></i></Link></td> */}

        
       </tr>
        )
      
       
    })
  return (
    <>
  <main class="content-div" >
  <p>Users
              {/* <div className="breadcrumb_div">
           <span className="breadcrumb_page"> Users</span>
            </div> */}
          </p>
        <div className="page-outerdiv">
        
        

        
          <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>
          <div className="row">

       


            <div className="col-md-12 ">
                <div className="menubar pull-left">
               <button onClick={this.addNew}><i className="fa fa-plus"></i><span>Add</span></button>
                  {/* <button>
                    <i className="fas fa-pencil-alt">
                    </i>
                    <span>Edit</span>
                </button> */}
                  
                  {hasPermission(user, ['Users_U']) && <button onClick={this.activeUsers.bind(this,true)} >
           
                    <i className="fas fa-toggle-on">
                    </i>
                    <span>Active</span>
                  </button>}
                  {hasPermission(user, ['Users_U']) && <button onClick={this.activeUsers.bind(this,false)} >
                   <i className="fas fa-toggle-off">
                    </i>
                    <span>Deactive</span>
                  </button>}
                  {/* {hasPermission(user, ['Users_U']) && <button onClick= {this.toggleConfirmation}>
                    <i className="fas fa-trash">
                    </i>
                    <span>Delete</span>
                  </button>} */}
                </div>
                {/* <button class="btn_primary pull-right mt-0  mr-0" type="button" onClick={this.goLoginHistory} >
                View Login History
        </button> */}
            </div>
           
             
          </div>
          <div className="row  mt-3">

          <MDBCol md="3"  className="mt-2" >
              <div class="input-group">
                <i className="fas fa-search fa-sm input-searchIcon"></i>
                <input type="text" id="emailId" onKeyDown={this.keyPress} value={this.state.fields["emailId"]} style={{ width: "60%" }} onChange={this.changeHandler.bind(this, "emailId")} class="form-control input-searchBox pad-2" placeholder="Email" name="adminAddress" onFocus={this.Focus} />
                <button type="button" data-test="button" className="closinginput" aria-label="Close"  onClick={this.handleClick} >
                  <span aria-hidden="true">×</span>
                </button>
                <div class="input-group-btn">
                  <button class="input-searchBtn find-btn" type="submit" onClick={this.onChangeEmail} >Find</button>
                </div>
                <span className="error_msg w3-animate-top mt-2"><span style={{color: "red"}}>{this.state.errors["selectChargepoint"]}</span></span>
                  <span className="error_msg w3-animate-top mt-2" id="notexists" style={{color: "red"}}>  <span style={{color: "red"}}>{this.state.errors["notexists"]}</span></span>
              </div>
             

        </MDBCol>
        <div className=" col-md-3  pull-right mt-0">
      <select className="browser-default custom-select select_height " id="organizationid"  onChange={this.onChangeOrg.bind(this)}>
    {organisationDTOS}
    </select>
    </div>
    <div className="col-md-3"></div>
   <div className="col-md-3 mt-2">
   <div className="pull-right">
                     <label className="sub-text pull-left" >Show entries</label>
                      <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv" >
                        <Form.Control as="select" className="showentry_sel custom_selectBox"  id="showEntries" onChange={this.showEntriesChange}> 
                          <option>10</option>
                          <option>20</option>
                          <option>30</option>
                          <option>40</option>
                          <option>50</option>
                        </Form.Control>
                    </Form.Group>                 
                </div>
   </div>
    
    </div>
      


          
          
          {/* {/ end page menu bar  /}
          
          {/ table start /} */}
         <div className="row mt-2 ">
           <div className="col-md-12">
          <Table striped hover size="sm" className="page-table">

            <thead>
              <tr>
              <th className="text-center"> 
              {/* <Form.Check 
                          custom
                          type="checkbox"
                          id={`custom-all`}
                          label = ""
                       /> */}
                       </th>
                <th>ID</th>
                <th>Email</th>
                {/* <th>First Name</th>
                <th>Last Name</th> */}
                {/* <th>Mobile No</th> */}
                <th>Role</th>
                <th>Status</th>
                {/* <th style={{display:this.state.actiondisplay}}>Action</th> */}

             </tr>
            </thead>
            <tbody>
             {userDTOS}
            </tbody>
          </Table>
          </div>
          <div className="col-md-12 mt-2 mb-4 text-right">
            <Pagination
              hideDisabled
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.pageSize}
              totalItemsCount={this.state.noOfRecords}
              onChange={this.handlePageChange.bind(this)}
            />
          </div> 
          
          {/* {/ End table  /} */}
          </div>
          </div>
         
          {/* {/ popup msg /} */}
          <MDBModal isOpen={this.state.modal3} toggle={this.toggle1} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle1}>Success</MDBModalHeader>
            <MDBModalBody>
            User deactivated successfully.
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="primary" onClick={this.tagOk1}>OK</MDBBtn>
            </MDBModalFooter>
          </MDBModal>
{/* 
             {/ popup msg /} */}
             <MDBModal isOpen={this.state.modal2} toggle={this.toggle2} size="md" className="model_top">
          <MDBModalHeader toggle={this.toggle2}>Success</MDBModalHeader>
          <MDBModalBody>
          User activated successfully.
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="primary" onClick={this.tagOk2} >OK</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        
{/* <MDBModal isOpen={this.state.modal1} toggle={this.toggleConfirmation} size="md" className="model_top">
      <MDBModalHeader toggle={this.toggleConfirmation}>Confirmation</MDBModalHeader>
      <MDBModalBody>
      Are you sure you want to delete?
      </MDBModalBody>
      <MDBModalFooter>
      <MDBBtn color="danger" onClick={this.toggleConfirmation}>CLOSE</MDBBtn>
      <MDBBtn color="primary" onClick={this.deleteUser}>YES</MDBBtn>
      </MDBModalFooter>
      </MDBModal> */}
      <MDBModal isOpen={this.state.modal1} toggle={this.toggleConfirmation} size="md" className="model_top">
              <MDBModalHeader toggle={this.toggleConfirmation}> {this.state.deleteHeader}</MDBModalHeader>
                <MDBModalBody>
                  {this.state.delete_msg}
                </MDBModalBody>
              <MDBModalFooter>
                  <MDBBtn color={this.state.deletecolor} onClick={this.toggleConfirmation}>{this.state.deleteText}</MDBBtn>
                  <MDBBtn color="primary" className={this.state.deleteButton} onClick={this.deleteUser}>YES</MDBBtn>
              </MDBModalFooter>
            </MDBModal>

      <MDBModal isOpen={this.state.modal} backdrop="static"  data-backdrop="static" data-keyboard="false"   size="md" className="model_top">
      <MDBModalHeader toggle={this.toggle}>Success</MDBModalHeader>
      <MDBModalBody>
        User deleted successfully.
      </MDBModalBody>
      <MDBModalFooter>
      {/* <MDBBtn color="secondary" onClick={this.toggle}>Close</MDBBtn> */}
      <MDBBtn color="primary" onClick={this.closeOkPopup}>OK</MDBBtn>
      </MDBModalFooter>
      </MDBModal>

      
{/* popup for session expire */}

<MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
     <MDBModalHeader toggle={this.togglesession}>Session has expired</MDBModalHeader>
     <MDBModalBody>
      Please login again.
     </MDBModalBody>
     <MDBModalFooter>
     {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
     <MDBBtn color="primary" onClick={this.sessiontagOk}>OK</MDBBtn>
     </MDBModalFooter>
     </MDBModal>

      </main>
 </>
  );
}
}

export default userManagementController;