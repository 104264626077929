import React from 'react';
import logo from '../logo.svg';
import '../App.css';
import { Switch, Route } from 'react-router-dom';
import { Router, browserHistory, IndexRoute } from 'react-router';
import { createBrowserHistory } from "history";
import * as baseUrl from './constants';
import { Alert } from 'reactstrap';

class resetpassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: { newpwd: '', confirmpwd: '', token: '' },
            sucess: {},
            reset_Password: false,
            errors: {},
            message: "",
            sucess: false,
            colour: 'sucess',
            activeItem: "1",
            Tokenexpired: false,
            Tokenmsg: ""
        }
    }

    async validateUrl() {
        var location = window.location.href;
        var allurl = location.split("/");
        var n = allurl.length;
        this.state.fields["token"] = allurl[n - 2];
        var url1 = baseUrl.LoginPath + "/system/authentication/unblock/" + allurl[n - 2];

        fetch(url1, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                "Authorization": localStorage.getItem("Authorization"),
            }
        }).then(resp => {
            if (resp.status == 404) {
                this.setState({ Tokenexpired: true, Tokenmsg: "Invalid unblock link. Please contact with administrator." });
                localStorage.clear()
            }
            if (resp.status == 400) {
                localStorage.clear()
                this.setState({ Tokenexpired: true, Tokenmsg: "This unblock link got expired, Please contact with administrator." });
            }
            if (resp.status == 200) {
                localStorage.setItem('unblock_user', true);
                this.props.history.push('/');
            }
            return resp.json();

        }).then(response => { } )
        .catch((error) => {
            console.log(error, "catch the loop")
        })
    }

    componentWillMount() {
        this.validateUrl();
    }

    render() {
        
        if (this.state.Tokenexpired == true) {
            return (
                <h6 className="text-center pt-3">{this.state.Tokenmsg}</h6>
            )

        } else {
            return (<h1></h1>)
        }
    }
};

export default resetpassword;