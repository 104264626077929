import React from 'react';
import { Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';
import './App.css';
import Login from './views/login';
import { hasRole, hasPermission } from './views/auth';
import forgotPassword from './views/forgotPassword';
import resetPassword from './views/resetpassword';
import updatePassword from './views/updatePassword';
import Header from './views/header';
// import Sidebar from './views/sidebar';
import UserManagementController from './views/userManagementController';
import addUserManagement from './views/addUserManagement';
import changePassword from './views/changePassword';
import ViewRegester from './views/viewRegester';
import $ from "jquery";
import appRegester from './views/appRegester';
import appRegestration from './views/appRegestration';
import EditRegestration from './views/editRegestration';
import unblockUser from './views/unblockUser';

const history = createHistory();
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login: { value: "" },
      user: true,
      page_name: ""
    }
  }

  componentWillMount() {
    var auth_token = localStorage.getItem("Authorization");
    if (auth_token != "" && auth_token != null) {
      var token = auth_token.split(" ");
      localStorage.setItem("choosen_tenant", "none");
    }

    this.setState({
      page_name: window.location.href
    })
    var location = window.location.href;
    var id = location.split("/");

    if ((id.length == 4 && id[3] == "") || (id[4] == "resetpassword"|| id[4] == "unblock")) {
      this.setState({
        value: "1"
      })
    } else {
      var user = localStorage.getItem("user");
      if (user == null) {
        window.location.replace('/');
        this.setState({
          user: false
        })
      }
    }
  }

  render() {


    debugger
    debugger
    if (this.state.value == 1) {
      return (
        // without header and sidebar pages 
        <BrowserRouter history={history}>
          <Route exact path="/" component={Login} />
          <Route exact path="/forgotPassword" component={forgotPassword} />
          <Route path="/:token/resetpassword" component={resetPassword} />
          <Route exact path="/updatePassword" component={updatePassword} />
          <Route path="/:token/unblock" component={unblockUser} />
        </BrowserRouter>
      );
    } else if (this.state.user) {
      const user = {
        permissions: [

        ],
      };
      if (localStorage.getItem("roleAccess") != undefined) {
        user.permissions = localStorage.getItem("roleAccess");
        var fdf = user.permissions;
        console.log(fdf);
        var dfd = hasPermission(user, ['ChargePoint_R']);
        debugger
      }



      return (
        <BrowserRouter history={history}>
          <div className="root">
            <Header />
            <Route path="/appRegester" component={appRegester} />
            <Route path="/appRegestration" component={appRegestration} />
            <Route path="/userManagementController" component={UserManagementController} />
            <Route path="/viewRegester" component={ViewRegester} />
            <Route path="/editRegestration" component={EditRegestration} />
            <Route exact path="/addUserManagement" component={addUserManagement} />
            <Route path="/changePassword" component={changePassword} />
          </div>
        </BrowserRouter>
      )
    }
  }
}

export default App;
