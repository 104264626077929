import React from 'react';
import logo from '../logo.svg';
import '../App.css';
import { Switch, Route } from 'react-router-dom';
import { Router, browserHistory, IndexRoute } from 'react-router';
import { createBrowserHistory } from "history";
import * as baseUrl from './constants';
import { Alert } from 'reactstrap';

class updatePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: { oldpwd: this.props.location.state.syspassword, newpwd: '' },
      sucess: {},
      update_Password: false,
      errors: {},
      message: "",
      sucess: false,
      colour: 'sucess',
      activeItem: "1",
      Tokenexpired: false,
      Tokenmsg: ""
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleLogin = this.handleLogin.bind(this)
  }

  //   async validateUrl(){
  //   var location=window.location.href;
  //   var allurl= location.split("/");
  //   var n=allurl.length;
  //   this.state.fields["token"] = allurl[n-2];
  //   var url1= baseUrl.URLPath+"system/authentication/verifytoken/"+allurl[n-2];
  //    debugger;
  //   fetch(url1, {
  //    method: 'GET', // or 'PUT'
  //     // data can be `string` or {object}!
  //     headers:{
  //       "content-type":"application/json",
  //       "Authorization":localStorage.getItem("Authorization"),

  //     }
  //    }).then(resp =>{
  //      debugger
  //   if(resp.status==404){

  //        //this.setState({errors: errors});
  //        this.setState({Tokenexpired:true,Tokenmsg:"Invalid forgot password link, please click on latest link or generate new forgot password link."}) ;
  //      // }else if(resp.status == 400){
  //       //this.props.history.push("signin");
  //       //  this.setState({errors:resp.json()}) ;
  //      }
  //       if(resp.status == 400){
  //         this.setState({Tokenexpired:true,Tokenmsg:"This forgot password link got expired, please generate new forgot password link."}) ;
  //      }
  //    return resp.json();

  //    }).then(response =>
  //      {
  //        debugger;

  //       //  alert("forget passowrd succefully");
  //      }
  //      ).catch((error) => {
  //       console.log(error, "catch the loop")
  //   })
  // }

  componentWillMount() {
    debugger;
    // this.validateUrl();
    //let fields = this.state.fields;

    //fields['oldpwd'] = this.history.state.state.syspassword; 
    // this.setState({fields})
  }

  //  handleValidation(field, e){

  //    let fields = this.state.fields;
  //    fields[field] = e.target.value; 
  //    let errors = {};
  //    let formIsValid = true;
  //    debugger;
  //    if(!fields["newpwd"]){
  //    formIsValid = false;
  //    errors["pwdempty"] = "Password  Cannot be Empty";
  //    }
  //    if(typeof fields["newpwd"] !== "undefined"){
  //       formIsValid = false;
  //       errors["entervalidpwd"] = "Please enter valid Password";
  //       } 
  //     if(!fields["confirmpwd"]){
  //    formIsValid = false;
  //    errors["confirmpassword"] = "confirmpassword  Cannot be Empty";
  //    }
  //   //  if(!fields["confirmpwd"]==["newpwd"]){
  //   //    formIsValid = false;
  //   //   errors["password"] = "New password and Confirm Password should be same";
  //   // }
  //    if(!fields["newpwd"].match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/)){

  //      formIsValid = false;
  //      errors["entervalidpwd"] = "Please enter valid password";
  //      }

  //    this.setState({errors: errors});
  //    return formIsValid;
  //    }


  handleSubmit(event) {
    event.preventDefault();
    debugger;
    let errors = {};
    var validPassword = false;
    var oldpwd = this.state.fields['oldpwd'];
    var newpwd = this.state.fields['newpwd'];
    if (newpwd == '') {
      errors["pwdempty"] = "Password cannot be empty";
    }

    if (newpwd == '') {
      errors["pwdempty"] = "Password cannot be empty";
    } else if (newpwd.length <= 20) {
        if (!newpwd.match("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&+=])[A-Za-z\\d!@#$%^&+=]{8,}$")) {
          errors["entervalidpwd"] = "Password must have at least 8 characters that include at least 1 lowercase character (a-z), 1 uppercase character (A-Z), 1 number (0-9) and 1 sepcial character (!@#$%^&+=)";
        } else{
        validPassword = true;
        }
    }
    else {
     errors["pwdexceedchars"] = "Password must not be morethan 20 characters.";
   }


    this.setState({ errors: errors });
    //  if(newpwd!=''&& confirmpwd!=''){
    // this.props.history.push('/')
    //  }
    if (newpwd != '' && validPassword){
      let url = baseUrl.URLPath + "v1.0/user/updatepassword";
      var newpwd;
      debugger;
      let data = { "oldPassword": this.state.fields.oldpwd, "newPassword": this.state.fields.newpwd };
      fetch(url, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(data), // data can be `string` or {object}!
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization"),
        }
      }).then(resp => {
        if (resp.status == 404) {
          errors["pwdempty"] = "Sorry the reset password link has expired.";
          this.setState({ errors: errors });
        } else {
          return resp.json();
        }
      }).then((response) => {
        if (response != undefined) {
          if(response.status==400){
            if(response.message!=""){
              errors["entervalidpwd"] = response.message
            }else{
              errors["entervalidpwd"] = "Password must have at least 8 characters that include at least 1 lowercase character (a-z), 1 uppercase character (A-Z), 1 number (0-9) and 1 sepcial character (!@#$%^&+=)";
            }
            this.setState({errors:errors});
            // localStorage.clear()
          }else if(response.status=="SUCCESS"){
            this.setState({ message: "Your password has been updated successfully!", colour: 'success' })
            this.onShowAlert();
            this.setState({
              update_Password: true
            })
            localStorage.clear()
          } else if(response.status=="FAILURE"){
            errors["entervalidpwd"] = response.errorMessage;
            this.setState({errors:errors});
          }
        }

      }).catch((error) => {
        console.log(error, "catch the loop")
      })

    }
    debugger;
    //    this.setState({errors: errors});
    //alert('A name was submitted: user -' + this.state.user + " and password - " + this.state.password);

  }

  handleChange(field, e) {
    //if(this.handleValidation(field, e)){ 
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  onShowAlert() {
    debugger

    this.setState({ sucess: true }, () => {
      window.setTimeout(() => {
        this.setState({ sucess: false })
      }, 50000000)
    });

  }
  handleLogin() {
    this.props.history.push('/');
  }
  render() {
    //     debugger;
    //     if (this.state.errors.status==200){
    //       return (
    //         <>
    //         <script type="text/javascript" >
    //           function preventBack(){window.history.forward()}
    //           setTimeout("preventBack()", 0);
    //           window.onunload=function(){null};
    //         </script>

    //   <script language="JavaScript">
    //   javascript:window.history.forward(1);
    //  </script>
    //           <div className="login-page">
    //              <header>
    //                       {/* Navbar */}

    //                       {/* Intro Section */}
    //                       <section className="view intro-2">
    //                         <div className="mask rgba-stylish-strong h-100 d-flex justify-content-center align-items-center">
    //                           <div className="container">
    //                             <div className="row">
    //                               <div className="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-5">
    //                                 {/* Form with header */}
    //                                 <div className="card wow fadeIn animated" data-wow-delay="0.3s" style={{visibility: 'visible', animationName: 'fadeIn', animationDelay: '0.3s'}}>
    //                                   <div className="card-body">
    //                                     {/* Header */}
    //                                     <div className="form-header purple-gradient">
    //                                       <h3 className="font-weight-500 my-2 py-1"> Reset password</h3>
    //                                     </div>
    //                                     {/* Body */}
    //                                     <div className="md-form">
    //                                       <i className="fas fa-user prefix white-text" />
    //                                       <input type="password" id="orangeForm-name" className="form-control" onChange={this.handleChange.bind(this, "newpwd")} value={this.state.fields["newpwd"]} />
    //                                       <label htmlFor="orangeForm-name">New-Password</label>
    //                                       <span className="error_msg w3-animate-top"> <p> <span style={{color: "red"}}>{this.state.errors["pwdempty"]}</span></p></span> 
    //                                       <span className="error-msg w3-animate-top"> <p> <span style={{color: "red"}}>{this.state.errors["entervalidpwd"]}</span></p></span>
    //                                     </div>
    //                                     <div className="md-form">
    //                                       <i className="fas fa-lock prefix white-text" />
    //                                       <input type="password" id="orangeForm-pass" className="form-control" onChange={this.handleChange.bind(this, "confirmpwd")} value={this.state.fields["confirmpwd"]} />
    //                                       <label htmlFor="orangeForm-pass">Confirm-Password</label>
    //                                       <span className="error_msg w3-animate-top"> <p> <span style={{color: "red"}} >{this.state.errors["confirmpassword"]}</span></p></span>

    //                                     </div>

    //                                     <div className="text-center">
    //                                     <button type='submit' className="btn login-btn btn-lg waves-effect waves-light" onClick={this.handleSubmit}>submit</button>
    //                                       <hr className="mt-4" />

    //                                       <div className="inline-ul text-center d-flex justify-content-center">
    //                                        <a className="txt2" href="/">
    //                                         Sign In
    //                                        </a>
    //                                     </div>
    //                                     </div>
    //                                    </div>
    //                                 </div>
    //                                 {/* Form with header */}
    //                               </div>
    //                             </div>
    //                           </div>
    //                         </div>
    //                       </section>

    //                       {/* Intro Section */}
    //                       </header>
    //                     </div>
    //                     </>

    //       );
    //     }
    //     else if(this.state.errors.status==400){

    //       return(
    //         <div> This Link is expo</div>
    //       );
    //     } else if(this.state.errors.status==404){

    //       return(
    //         <div>You have opened old link, please open latest link or generate new link</div>
    //       );
    //     } else{
    //       return (<div>link</div>);
    //     }

    //    }



    if (this.state.Tokenexpired == true) {
      return (
        <h6 className="text-center pt-3">{this.state.Tokenmsg}</h6>
      )

    } else if (this.state.update_Password == true) {
      return (
        <div className="login-page">
          <header>
            {/* Navbar */}

            {/* Intro Section */}
            <section className="view intro-2">
              <div className="mask rgba-stylish-strong h-100 d-flex justify-content-center align-items-center">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-5">
                      {/* Form with header */}
                      <div className="card wow fadeIn animated" data-wow-delay="0.3s" style={{ visibility: 'visible', animationName: 'fadeIn', animationDelay: '0.3s' }}>
                        <div className="card-body">
                          {/* Header */}
                          <div className="col-md-12 login_icon">
                            <img src={process.env.PUBLIC_URL + "/assets/img/webasto-logo-png-transparent.png"} style={{ width: "138px" }} />
                          </div>
                          <p class="h4 text-center mb-3 pb-3 pt-2 title_font">Update Password</p>

                          {/* Body */}
                          <Alert color={this.state.colour} isOpen={this.state.sucess} className="mt-2" >{this.state.message}</Alert>
                          <div className="text-center" >
                            <button type='submit' className="btn login-btn btn-lg waves-effect waves-light" onClick={this.handleLogin}>Sign In</button>

                            {/* 
                             <div className="inline-ul text-center d-flex justify-content-center">
                              <a className="txt2" href="/">
                               Sign In
                              </a>
                           </div> */}
                          </div>
                        </div>
                      </div>
                      {/* Form with header */}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* Intro Section */}
          </header>
        </div>
      )

    } else {

      return (

        <div className="login-page">
          <header>
            {/* Navbar */}

            {/* Intro Section */}
            <section className="view intro-2">
              <div className="mask rgba-stylish-strong h-100 d-flex justify-content-center align-items-center">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-5">
                      {/* Form with header */}
                      <div className="card wow fadeIn animated" data-wow-delay="0.3s" style={{ visibility: 'visible', animationName: 'fadeIn', animationDelay: '0.3s' }}>
                        <div className="card-body">
                          {/* Header */}
                          <div className="col-md-12 login_icon">
                            <img src={process.env.PUBLIC_URL + "/assets/img/webasto-logo-png-transparent.png"} style={{ width: "138px" }} />
                          </div>
                          <p class="h4 text-center mb-3 pb-3 pt-2 title_font">Update Password</p>

                          {/* Body */}
                          <Alert color={this.state.colour} isOpen={this.state.sucess} className="mt-2" >{this.state.message}</Alert>
                          <div className="md-form">
                            <i className="fas fa-lock prefix white-text" />
                            <input type="password" disabled id="orangeForm-pass" className="form-control" onChange={this.handleChange.bind(this, "oldpwd")} value={this.state.fields["oldpwd"]} />
                            <label htmlFor="orangeForm-pass" class="active">Old-Password</label>
                            {/* <span className="error_msg1 w3-animate-top"><p> <span style={{color: "red"}} >{this.state.errors["oldPassword"]}</span></p></span> */}
                          </div>
                          <div className="md-form">
                            <i className="fas fa-lock prefix white-text" />
                            <input type="password" id="orangeForm-name" className="form-control active" onChange={this.handleChange.bind(this, "newpwd")} value={this.state.fields["newpwd"]} />
                            <label htmlFor="orangeForm-name">New-Password</label>
                            <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["pwdempty"]}</span></p></span>
                            <span className="error_msg1 w3-animate-top"> <p> <span style={{ color: "red" }}>{this.state.errors["entervalidpwd"]}</span></p></span>
                            <span className="error_msg1 w3-animate-top">
                              <p><span style={{ color: "red"}}>{this.state.errors["pwdexceedchars"]}</span></p>
                            </span>
                          </div>


                          <div className="text-center">
                            <button type='submit' className="btn login-btn btn-lg waves-effect waves-light mb-4" onClick={this.handleSubmit}>submit</button>

                            {/* 
                         <div className="inline-ul text-center d-flex justify-content-center">
                          <a className="txt2" href="/">
                           Sign In
                          </a>
                       </div> */}
                          </div>
                        </div>
                      </div>
                      {/* Form with header */}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* Intro Section */}
          </header>
        </div>
      )
    }


  }
};

export default updatePassword;