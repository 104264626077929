import React from 'react';
import {Switch , Route , Link} from 'react-router-dom';
import  $ from "jquery";
import { hasRole, hasPermission } from './auth';
import * as jwtJsDecode from 'jwt-js-decode';
import * as baseUrl from './constants';


class Sidebar extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
            inventoryDashboardDisplay:"",
            enablecronjob: "true",
            logsdisplay:"",
            operationdisplay:"",
            brokerdisplay:"",
            connectorDisplay:"",
            vehicledisplay:"",
            enableBroker: "true",
            enableConnector:"true",
            enableVehicle: "true",
            enableCSOperation: "true",
            dashboarddisplay:"",
            ocppTagsDisplay:"",
          
           

            hideActionBtn:"none", // show hide btn varible based on permission 
            writePermission:this.props.ChargePoint_W, // ChargePoint_W conndition
			listOpen0: false, // done by manoj ux designer 
			listOpen1: false,
			listOpen2: false,
			listOpen3: false,
			listOpen4: false,
            listOpen5: false,
            listOpen6: false,
			listOpen7: false,
			listOpen8: false,
			listOpen9: false,
            listOpen10: false,
			listOpen11: false,
			listOpen13: false,
            listOpen12: false, 
            listOpen14: false, 
            listOpen15: false,               
            prev_id:"",
            // disableMenu:"",
            user:{
                permissions: [
                    
                ],
            }
		};
      
        this.toggleMenuOperation = this.toggleMenuOperation.bind(this);
        this.toggleList = this.toggleList.bind(this);
        this.getEnableBrokerStatus = this.getEnableBrokerStatus.bind(this);
        
    }
    

    //  toogle functionality of sidebar done by manoj ux designer 
    toggleList(img, index, e ){
        var elemnt  = e.target.parentElement;
        $(".collapse").removeClass("show");
        var list ="";
        if(index==this.state.prev_id){
        }else{
            this.setState({
                listOpen0: false, 
                listOpen1: false,
                listOpen2: false,
                listOpen3: false,
                listOpen4: false,
                listOpen5: false,
                listOpen6: false, 
                listOpen7: false,
                listOpen8: false,
                listOpen9: false,
                listOpen10: false,
				listOpen11:false,
                listOpen13:false,
                listOpen12:false,
                listOpen14 :false,
                listOpen15: false,
            })
        }
        switch(index) {
            case 0: this.setState(prevState => ({listOpen0: !prevState.listOpen0 })); break;
            case 1: this.setState(prevState => ({listOpen1: !prevState.listOpen1 }));break;
            case 2: this.setState(prevState => ({listOpen2: !prevState.listOpen2 }));break;
            case 3: this.setState(prevState => ({listOpen3: !prevState.listOpen3 }));break;
            case 4: this.setState(prevState => ({listOpen4: !prevState.listOpen4 }));break;
            case 5: this.setState(prevState => ({listOpen5: !prevState.listOpen5 }));break;
            case 6: this.setState(prevState => ({listOpen6: !prevState.listOpen6 }));break;
            case 7: this.setState(prevState => ({listOpen7: !prevState.listOpen7 }));break;
            case 8: this.setState(prevState => ({listOpen8: !prevState.listOpen8 }));break;
            case 9: this.setState(prevState => ({listOpen9: !prevState.listOpen9 }));break;
            case 10: this.setState(prevState => ({listOpen10: !prevState.listOpen10 }));break;
			case 11: this.setState(prevState => ({listOpen11: !prevState.listOpen11 }));break;
            case 13: this.setState(prevState => ({listOpen13: !prevState.listOpen13 }));break;
            case 12: this.setState(prevState => ({listOpen12: !prevState.listOpen12 }));break;
            case 14: this.setState(prevState => ({listOpen14: !prevState.listOpen14 }));break;
            case 15: this.setState(prevState => ({listOpen15: !prevState.listOpen15}));break;

          } 
          this.state.prev_id = index;
          localStorage.removeItem("chargerId");
          if(index == "15"){
            localStorage.setItem('operation', 'operationMenu')
            localStorage.removeItem("operationId")
          }
    }
    


    //   toggleList1(){
    //     this.setState(prevState => ({
    //         listOpen1: !prevState.listOpen1
    //     }))
    //   }

    //   toggleList2(){
    //       debugger;
    //     this.setState(prevState => ({
    //         listOpen2: !prevState.listOpen2
    //     }))
       
    //   }
      toggleMenuOperation(){
          localStorage.setItem('operation', 'operationMenu')
          localStorage.removeItem("operationId")
              }
    
    //   toggleList3(){
    //     this.setState(prevState => ({
    //         listOpen3: !prevState.listOpen3
    //     }))
    //   }

      toggleList12(){
        this.setState(prevState => ({
            toggleList12: !prevState.toggleList12
        }))
      }
  


    //   toggleList5(){
    //     this.setState(prevState => ({
    //         listOpen5: !prevState.listOpen5
    //     }))
    //   }

    

    componentDidMount(){
        var location = window.location.href;
        var id = location.split("/");
        
        if(id[3] == "dashboard"){
            this.setState({
                listOpen0: true
            })
        }
        if(id[3] == "csrLanding"){
            this.setState({
                listOpen11: true
            })
        }
        if(id[3] == "inventoryList"){
            this.setState({
                listOpen7:true
            })
        }
        if(id[3] == "cpPingPong"){
            this.setState({
                listOpen7:true
            })
        }
        
        // permission related code
        // 
        this.state.user.permissions=localStorage.getItem("roleAccess");
        this.state.choosenTenant = localStorage.getItem("choosen_tenant");

        if(this.state.choosenTenant=="none" && hasPermission(this.state.user, ['SwitchAccount']) ){
            this.setState({
                disableMenu:"disabled-link"
            })
        }
        else{
              if(this.state.choosenTenant!="none" && hasPermission(this.state.user, ['SwitchAccount']) ){
                this.setState({
                    disableMenu:""
                })
              }
        }

    
    
       this.state.user.permissions=localStorage.getItem("roleAccess");
        var fsd=localStorage.getItem("roleAccess");
        this.state.writePermission=hasPermission(this.state.user,['OCPPTags_R']);
                if(this.state.writePermission==true){
                    this.setState({
                    hideActionBtn:"inline-block"
                    })
                }

      this.getEnableBrokerStatus();
    }

        getEnableBrokerStatus(){
        
            let url = baseUrl.LoginPath +"/login/v1.0/enableInfo";  
             fetch(url,{
              method: "GET",
              headers: {
                    "content-type":"application/json",
                    "Authorization":localStorage.getItem("Authorization")
                  }
              })
              .then((resp) => {
                debugger;
                if(resp.status==401){
                  this.togglesession();
            
                  localStorage.clear();
                 
                }
                if(resp.status==200){
                  return resp.json();
                }
              }) 
              .then((response) =>{
                if(response!=undefined){
                    localStorage.setItem("broker",true);
                   }
              })
              .catch((error) => {
                //   console.log(error, "catch the loop")
              })
            
          }
          
    
    render() {
        const{listOpen0}  = this.state
        const{listOpen1} = this.state
        const{listOpen2} = this.state
        const{listOpen3} = this.state
        const{listOpen4} = this.state
        const{listOpen5} = this.state
        const{listOpen6} = this.state
        const{listOpen7} = this.state
        const{listOpen8} = this.state
        const{listOpen9} = this.state
		const{listOpen10} = this.state
		const{listOpen11} = this.state
        const{listOpen13} = this.state
        const{listOpen12} = this.state
        const{listOpen14} = this.state
        const{listOpen15} = this.state



        const user = this.state.user



        let logOffAccount = localStorage.getItem("tenant")


        let getTenanatFord = localStorage.getItem("switchOrgName");
       if(getTenanatFord != "FORD" && getTenanatFord != null){
        // this.state.cronjobdisplay = "none";
        localStorage.removeItem("enableFord")
       }
      





        let getTenant = localStorage.getItem("enableFord");

    if(getTenant == "ford" || getTenanatFord == "FORD" || logOffAccount == "ford"){
        this.state.inventoryDashboardDisplay="block";
            this.state.logsdisplay = "block";

             // condition for enablecronjob
             let cronJob = localStorage.getItem("cronJobStatus")
             if(this.state.enablecronjob == cronJob){
              this.state.logsdisplay = "block";
             }
             else {
                this.state.logsdisplay = "none";
          
              }
          }
          else {
            this.state.logsdisplay = "none";
      this.state.inventoryDashboardDisplay="none";
          }






   
    // else {
    //   this.state.logsdisplay = "none";

    // }

        // condition for enableCSOperation
          let csOperation = localStorage.getItem("csOperation")
         if(this.state.enableCSOperation == csOperation){
            this.state.operationdisplay = "block";
           }
          else {
            this.state.operationdisplay = "none";

          }
           // condition for enableBroker
           let Broker = localStorage.getItem("broker")
          if(this.state.enableBroker == Broker){
            this.state.brokerdisplay = "block";
           }
          else {
            this.state.brokerdisplay = "none";
          }
        //   condition for connector enable
        let getConnectorStatus = localStorage.getItem("saveConnector")
        if(this.state.enableConnector == getConnectorStatus){
            this.state.connectorDisplay = "block";
        }
        else{
            this.state.connectorDisplay = "none";

        }
           // condition for enableVehicle
          let vehicle = localStorage.getItem("vehicle")
           if(this.state.enableVehicle == vehicle){
            this.state.vehicledisplay = "block";
           }
          else {
            this.state.vehicledisplay = "none";
          }

        
         
         if(localStorage.getItem("role") == "WEBASTO_CUSTOMER_SERVICE_REPRESENTATIVE"){
            this.state.dashboarddisplay = "none";
            this.state.brokerdisplay = "none";
            this.state.vehicledisplay = "none"
          }
          else{
           this.state.vehicledisplay = "block"

            let Broker = localStorage.getItem("broker")
           if(this.state.enableBroker == Broker){
             this.state.dashboarddisplay = "block";
             this.state.brokerdisplay = "block";
             this.state.vehicledisplay = "block"
            }
            else{
            this.state.dashboarddisplay = "block";
            this.state.brokerdisplay = "none";
            this.state.vehicledisplay = "none"
        }

         }


        //  if(localStorage.getItem("role") == "WEBASTO_END_USER_RESIDENTIAL"){
        //      this.state.ocppTagsDisplay = "none";
        //    }
        //   else{
        //     this.state.ocppTagsDisplay = "block";
        //    }
        

        return (
            <>
            <div className="sidebar-main-div">
                <div id="slide-out" className="side-nav sn-bg-4">
                    <ul className="custom-scrollbar ps ps--active-y">
                    <li>
                            <ul className="collapsible collapsible-accordion left-sidebar">
                              <li style={{display:this.state.vehicledisplay}}  className={ ` ${listOpen9 ? "active" : "" } `} onClick={this.toggleList.bind(this, "w-dashboard.png", 9)}>
                                    <Link to="./appRegestration" className="collapsible-header waves-effect">
                                    {listOpen9 ? <img src={process.env.PUBLIC_URL + "/assets/img/w-svgvehicles.svg"} alt="logo" className="menuicon_img" /> : <img src={process.env.PUBLIC_URL + "/assets/img/svgvehicles.svg"} alt="logo" className="menuicon_img" />  } 
                                   
                                    App Registration</Link>
                                </li>
                                {/* {hasPermission(user, ['CSR_R']) &&
                                <li  className={ ` ${listOpen11 ? "active" : "" } `}>
                                  <Link to="./userManagementController" className="collapsible-header waves-effect" onClick={this.toggleList.bind(this, "w-csr.png", 11 )}>
                                    {listOpen11 ? <img src={process.env.PUBLIC_URL + "/assets/img/w-csr.svg"} alt="logo" className="menuicon_img" /> : <img src={process.env.PUBLIC_URL + "/assets/img/csr.svg"} alt="logo" className="menuicon_img" />  } 
                                    OAuth Users</Link>
                                </li>
    } */}
                            </ul>
                        </li>
                        {/*
                        <!-- Side navigation links -->*/}
                    </ul>
                    <div className="sidenav-bg mask-strong"></div>
                </div>
            </div>
        </>
    // <!-- end Sidebar navigation -->
        )
    }
}
export default Sidebar; 
