import React from 'react';
import { MDBBtn, MDBRow, MDBCol, MDBInput, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter } from 'mdbreact';
import * as baseUrl from './constants';
import { Form, Table, Pagination, Check, Accordion, Card } from 'react-bootstrap';
import $ from "jquery";
import Datetime from 'react-datetime';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createBrowserHistory } from 'history';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Header from './header';
import { Alert } from 'reactstrap';
import { hasRole, hasPermission } from './auth';
import Switch from "react-switch";

const history = createBrowserHistory();

class viewRegester extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hideActionBtn: "none", // show hide btn varible based on permission 
            AppId: "",
            modalConfirmation: false,
            message: "",
            colour: 'sucess',
            sucess: false,
            modalConfirmation: false,
            collapseID: "",
            controllerData: [],
            dataArray: [],

        }

        this.backToList = this.backToList.bind(this)
        this.toggleConfirmation = this.toggleConfirmation.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.toggleDelete = this.toggleDelete.bind(this)
        this.tagOk = this.tagOk.bind(this)
        this.togglesession = this.togglesession.bind(this)
        this.sessiontagOk = this.sessiontagOk.bind(this)
        this.onShowAlert = this.onShowAlert.bind(this);

    }

    onShowAlert() {


        this.setState({ sucess: true }, () => {
            window.setTimeout(() => {
                this.setState({ sucess: false })
            }, 5000)
        });

    }

    toggleDelete = () => {
        this.setState({
            modalDelete: !this.state.modalDelete
        });
    }

    tagOk = () => {
        this.props.history.push('/appRegestration');
    }

    toggleConfirmation = () => {
        this.setState({
            modalConfirmation: !this.state.modalConfirmation
        });
    }

    handleDelete(event) {
        this.setState({
            modalConfirmation: !this.state.modalConfirmation
        });

        event.preventDefault();
        this.deleteApplication();
    }


    toggleDeleteClose() {
        this.setState({
            modalDelete: !this.state.modalDelete
        });
    }

    async togglesession() {
        ;
        this.setState({
            modalsession: !this.state.modalsession
        });
    }

    sessiontagOk() {
        history.push('/');
        window.location.reload();
    }


    backToList() {
        this.props.history.push('/appRegestration');
    }
    componentDidMount() {
        if (this.props.location.state == null) {
            this.state.AppId = this.state.AppId
        } else {
            this.state.AppId = this.props.location.state;
        }
        this.getApplicationDetails()
    }

    async getApplicationDetails() {
        var timeZone = localStorage.getItem("timezone");
        document.getElementById("loader_image_div").style.display = "block";

        var url = baseUrl.URLPath + "application/" + this.state.AppId;
        await fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": localStorage.getItem("Authorization")
            }
        })
            .then((resp) => {
                ;
                if (resp.status == 401) {
                    document.getElementById("loader_image_div").style.display = "none";

                    this.togglesession();
                    localStorage.clear();
                }
                if (resp.status == 500) {
                    document.getElementById("loader_image_div").style.display = "none";

                    alert("internal server error")
                }
                if (resp.status == 200) {
                    document.getElementById("loader_image_div").style.display = "none";

                    return resp.json();
                }
            })
            .then((response) => {

                if (response != undefined) {
                    this.setState({
                        dataArray: response,
                    })
                }
            })
            .catch((error) => {
                console.log(error, "catch the loop")
            })
    }

    async deleteApplication() {
        var timeZone = localStorage.getItem("timezone");
        ;
        var url = baseUrl.URLPath + "application/" + this.state.AppId;
        await fetch(url, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": localStorage.getItem("Authorization")
            }
        })
            .then((resp) => {
                ;
                if (resp.status == 401) {
                    this.togglesession();
                    localStorage.clear();
                }
                if (resp.status == 500) {
                    alert("internal server error")
                }
                if (resp.status == 200) {
                    this.toggleDelete()
                }
                return resp.json();
            })
            .then((response) => {
                if (response.status == 400) {
                    this.setState({ message: response.message, colour: 'danger' })
                    this.onShowAlert();
                }
             
            })
            .catch((error) => {
                console.log(error, "catch the loop")
            })
    }


    toggleCollapse(collapseID) {
        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }))
    }

    render() {
        const { collapseID } = this.state;
        let controllerData = this.state.dataArray["accessedAPIs"];
        let controllerAccRow ="";
        if (controllerData != "" && controllerData != undefined) {
             controllerAccRow = controllerData.map((controller, index) => {
                return (
                    <Card className="logs_row">
                        <Accordion.Toggle as={Card.Header} eventKey={index} onClick={this.toggleCollapse.bind(this, `custom-${index}`)}>
                            <div className="row">
                                <div class="col-md-10">
                                    <label className='pt-1'>
                                        {controller.controller}
                                    </label>
                                </div>
                                <div className="col-md-2 accordion_angle pt-1">
                                    <i className={collapseID === `custom-${index}` ? "fa fa-angle-up" : "fa fa-angle-down"} />
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={index} id={`custom-${index}`}>
                            <Card.Body>
                                <Table striped hover size="sm" className="page-table mt-0 mb-0">
                                    <tbody>
                                        {
                                            controller.apis.map((variable, i) => {
                                                return (
                                                    <tr>
                                                        <td>{variable.httpMethod}</td>
                                                        <td>{variable.api}</td>
                                                        <td>{variable.method}</td>
                                                        <td className='text-right p-1'>{variable.methodName}</td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                );
            })
        }

        return (

            <>
                <main class="content-div" >
                    <p>View Register Application
                        <div className="breadcrumb_div">
                            <Link to="/appRegestration">App Registration</Link > &gt; <span className="breadcrumb_page">Register &gt; View</span>
                        </div>
                    </p>
                    <div className="page-outerdiv">
                        {/* breadCrumb */}
                        {/* <div className="breadcrumb_div"><a> Charge Point > <Link to="/chargingSchedule" > <span className="">Charging Schedule</span></Link> > </a>View</div> */}
                        {/* end breadCrumb */}
                        <div className="row">
                            <div className="col-md-12">
                                <div className="pull-left">
                                    <button className="btn_primary" onClick={this.backToList} type="button" data-test="button"><i class="fa fa-angle-left mr-2" aria-hidden="true"></i> Back</button>

                                </div>
                            </div>
                        </div>
                        <form
                            className='needs-validation'
                            onSubmit={this.toggle1}
                            noValidate
                        >

                            <MDBRow className="mb-4 mt-3 " >
                                <MDBCol sm="12">

                                    <Alert color={this.state.colour} isOpen={this.state.sucess} className="mt-2 b-1" >{this.state.message}</Alert>

                                    <div class=" " style={{ width: "100%" }}>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="content-innerdiv">
                                                    <div className="" >
                                                        <label>
                                                            Application Name:
                                                        </label>
                                                        <label >
                                                            {this.state.dataArray["applicationName"]}
                                                        </label>
                                                    </div>
                                                    <div className=" ">
                                                        <label>
                                                            Application Type:
                                                        </label>
                                                        <label>
                                                            {this.state.dataArray["applicationType"]}
                                                        </label>
                                                    </div>
                                                    <div className="">
                                                        <label>
                                                            Application Domain or IP:
                                                        </label>
                                                        <label className='domain-wordwrap'>
                                                       {String(this.state.dataArray["domain"])} 
                                                        </label>
                                                    </div>
                                                    <div className="">
                                                        <label>
                                                            Status:
                                                        </label>
                                                        <label>
                                                            {this.state.dataArray["status"]? "Active":"Inactive"}
                                                        </label>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="content-innerdiv">
                                                    <div className="">
                                                        <label>
                                                            Redirect URL:
                                                        </label>
                                                        <label>
                                                            {this.state.dataArray["redirectURL"]}
                                                        </label>
                                                    </div>
                                                    <div className="">
                                                        <label>
                                                            Description:
                                                        </label>
                                                        <label>
                                                            {this.state.dataArray["desc"]}
                                                        </label>
                                                    </div>
                                                    <div className="">
                                                        <label>
                                                            Is Blocked:
                                                        </label>
                                                        <label>
                                                            {this.state.dataArray["redirection"] ? "Yes":"No"}
                                                        </label>
                                                    </div>
                                                    <div className="">
                                                        <label>
                                                            Is Redirection:
                                                        </label>
                                                        <label>
                                                            {this.state.dataArray["redirection"]? "true":"false"}
                                                        </label>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </MDBCol>
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <span className="form-title charge_pl mt-3" >Controllers List: </span>
                                    </div>
                                    <MDBCol md="12">
                                        <Accordion defaultActiveKey="0">
                                            {controllerAccRow}
                                        </Accordion>
                                    </MDBCol>
                                </div>

                                {/* <MDBCol md="12" className="text-right">
                                    <button className="btn_secondary mt-5 mr-3" onClick={this.toggleConfirmation} id="deleteBtn" type="button" data-test="button">Delete <i class="fas fa-trash-alt ml-2"></i></button>
                                    <Link to={{
                                        pathname: '/editRegestration',
                                        state: this.state.AppId,
                                    }}>
                                        <button className="btn_primary mt-5 mr-3" onClick={this.editTag} id="editBtn" type="button" data-test="button">Edit <i class="fas fa-pencil-alt ml-2"></i></button>
                                    </Link>

                                </MDBCol> */}
                            </MDBRow>
                        </form>
                    </div>
                    <MDBModal isOpen={this.state.modalDelete} size="md" className="model_top">
                        <MDBModalHeader toggle={this.toggleDelete}>Success</MDBModalHeader>
                        <MDBModalBody>
                        Regestered successfully deleted.
                        </MDBModalBody>
                        <MDBModalFooter>
                            <button type="button" data-test="button" onClick={this.tagOk} className="btn_primary">OK</button>
                        </MDBModalFooter>
                    </MDBModal>

                    <MDBModal isOpen={this.state.modalConfirmation} toggle={this.toggleConfirmation} size="md" className="model_top">
                        <MDBModalHeader toggle={this.toggleConfirmation}> Confirmation </MDBModalHeader>
                        <MDBModalBody>
                            Are you sure you want to delete ?
                        </MDBModalBody>
                        <MDBModalFooter>
                            <button type="button" data-test="button" onClick={this.toggleConfirmation} className="btn_primary">Close</button>
                            <button type="button" data-test="button" onClick={this.handleDelete} className="btn_primary">YES</button>
                        </MDBModalFooter>
                    </MDBModal>

                    <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
                        <MDBModalHeader toggle={this.togglesession}>Session has expired</MDBModalHeader>
                        <MDBModalBody>
                            Please login again.
                        </MDBModalBody>
                        <MDBModalFooter>
                            <button type="button" data-test="button" onClick={this.sessiontagOk} className="btn_primary">OK</button>
                        </MDBModalFooter>
                    </MDBModal>
     {/* loader */}
     <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
          <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
        </div>
          {/* end loader */}
          
                </main>
            </>
        );
    }
}

export default viewRegester;

