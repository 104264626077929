import React from 'react';
import { MDBInput, MDBCol, MDBRow, MDBModal, MDBBtn, MDBModalHeader, MDBModalBody, MDBModalFooter } from "mdbreact";
import { Form, Table, Check } from 'react-bootstrap'
import * as baseUrl from './constants';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import $, { get } from "jquery";
import Header from './header';
import { createBrowserHistory } from 'history';
// import { hasRole, hasPermission } from './auth';
import Pagination from "react-js-pagination";
// import './cpDashboard.css';

const history = createBrowserHistory();

class appRegestration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      getRegestrationList: [],
      errors: {},
      noOfRecords: '',
      data: new Map(),
      pageSize: "10",
      activePage: 1,
      pageNo: 1,
      delete_msg: "",
      deleteHeader: "",
      deleteButton: "",
      deleteText: "",
      deletecolor: "danger",
      activeArray: [],
      checked: false,
      activeDeactiveArray: '',
      list: [],
      page_title: "Users",
      sucess: false,

      model1: "false",
      model2: "false",
      id: "",
      dataArray: [],
      org_name: '',
      modalsession: false,
      user: {
        permissions: [

        ],
      },
      viewblocked: false,
      errorCode: '',
      desc: '',
      attempts: '',
      modalrelease: false,
      Id: '',
      modalinvalidate: false
    }
    this.addNew = this.addNew.bind(this)
    this.closeModel = this.closeModel.bind(this)
    this.getAppRegList = this.getAppRegList.bind(this)
    this.activeUsers = this.activeUsers.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.toggle1 = this.toggle1.bind(this)
    this.toggle2 = this.toggle2.bind(this)
    this.tagOk2 = this.tagOk2.bind(this)
    this.tagOk1 = this.tagOk1.bind(this)
    this.deleteUser = this.deleteUser.bind(this)
    this.toggleConfirmation = this.toggleConfirmation.bind(this)
    this.closeOkPopup = this.closeOkPopup.bind(this)
    this.toggle = this.toggle.bind(this)
    this.togglesession = this.togglesession.bind(this)
    this.sessiontagOk = this.sessiontagOk.bind(this)

    this.changeHandler = this.changeHandler.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.showEntriesChange = this.showEntriesChange.bind(this);
    this.toggleblocked = this.toggleblocked.bind(this);
    this.togglemodal = this.togglemodal.bind(this);
    this.togglerelease = this.togglerelease.bind(this);
    this.invalidate = this.invalidate.bind(this);
    this.toggleinvalidate = this.toggleinvalidate.bind(this);

  }
  toggleinvalidate() {
    this.setState({ modalinvalidate: !this.state.modalinvalidate });
  }
  invalidate(id) {
    let url = baseUrl.URLPath + "invalidate/session?app_id=" + id;

    fetch(url, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    }).then((resp) => resp.json())
      .then((response) => {
        if (response != undefined) {
          this.toggleinvalidate();
        }
      })
  }
  togglerelease() {
    // this.setState({modalrelease: !this.state.modalrelease});
    window.location.reload();
  }
  togglemodal(errorcode, desc, attempts, id) {
    this.setState({
      errorCode: errorcode,
      desc: desc,
      attempts: attempts,
      Id: id
    });
    this.toggleblocked();
  }
  toggleblocked() {
    this.setState({ viewblocked: !this.state.viewblocked });
  }
  showEntriesChange() {
    var entries = document.getElementById("showEntries").value;
    // this.state.data.clear();
    // this.setState({pageSize:entries});
    this.state.pageSize = entries;
    this.state.activePage = 1;
    this.getAppRegList(entries, 1);
  }
  handlePageChange(pageNumber) {


    if (pageNumber != this.state.activePage) {
      var data = this.state.data;
      var pageData = data.get(pageNumber);
      if (pageData == undefined) {
        this.setState({ activePage: pageNumber });
        this.getAppRegList(this.state.pageSize, pageNumber);
        this.state.list[0]= "";
      } else {
        this.setState({ activePage: pageNumber });
      }
    }
  }

  changeHandler(field, e) {

    let fields = this.state.fields;
    fields[field] = e.target.value;
    fields.emailid = document.getElementById('emailId')
    this.state.errors["selectChargepoint"] = "Please enter email id"
    this.setState({ fields });
    this.state.errors["selectChargepoint"] = ""
    $("#notexists").text(" ");
  }





  async togglesession() {

    this.setState({
      modalsession: !this.state.modalsession
    });
  }


  sessiontagOk() {
    // this.props.history.push('/');
    history.push('/');
    window.location.reload();
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });

  }




  toggleConfirmation(event) {

    this.setState({
      modal1: !this.state.modal1
    });
    // event.preventDefault();
    if (this.state.list[0] != "" && this.state.list[0] != undefined) {



      this.setState({
        delete_msg: "Are you sure you want to delete?",
        deleteHeader: "Confirmation",
        deleteButton: "",
        deleteText: "Close",
        deletecolor: "danger"
      })
    } else {

      this.setState({
        delete_msg: "Please select the Application",
        deleteHeader: "Warning",
        deleteButton: "d-none",
        deleteText: "Ok",
        deletecolor: "primary"
      })
    }

  }


  closeOkPopup() {
    this.setState({
      modal: !this.state.modal
    });
    // this.props.history.push('/userManagementController');
    window.location.reload();
  }


  tagOk1() {
    window.location.reload();
  }
  tagOk2() {
    window.location.reload();
  }

  toggle1() {
    this.setState({
      modal3: !this.state.modal3
    });
  }
  toggle2 = () => {
    this.setState({
      modal2: !this.state.modal2
    });
  }

  closeModel() {
    window.location.reload();
  }




  addNew() {
    this.props.history.push('/appRegester');
  }
  // calling function when click on checkbox
  handleChange(id, identifier, event) {

    let arr = [];
    var newlist = [];
    var checkedValue = document.getElementById(id).checked;
    if (checkedValue == true) {
      if (this.state.list[0] != "" && this.state.list[0] != undefined) {
        document.getElementById(this.state.list[0]).checked = false;
        document.getElementById(id).checked = true;
      }
      this.state.list[0] = id;
      this.setState({
        activeArray: identifier
      });
    }
    if (checkedValue == false) {
      this.state.list[0] = "";
      document.getElementById(id).checked = false;
      this.setState({
        activeArray: ""
      });
    }
    this.state.activeDeactiveArray = identifier;
  }
  releaseFromBlock(id) {
    let url = baseUrl.URLPath + "release/user/" + id;

    fetch(url, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization")
      }
    }).then((resp) => resp.json())
      .then((response) => {
        if (response != undefined) {
          this.toggleblocked();
          this.togglerelease();
        }
      })
  }
  getAppRegList(pageSize, pageNo) {
    document.getElementById("loader_image_div").style.display = "block";

    var url = baseUrl.URLPath + "application?pageSize=" + pageSize + "&pageNo=" + pageNo;
    //localhost/cpms/api/application?pageNo={}&pageSize={}
    fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "Authorization": localStorage.getItem("Authorization"),
      }
    })
      .then((resp) => {

        if (resp.status == 401) {
          this.togglesession();
          document.getElementById("loader_image_div").style.display = "none";

          localStorage.clear();

        }
        if (resp.status == 200) {
          document.getElementById("loader_image_div").style.display = "none";

          return resp.json();
        }
      })
      .then((response) => {

        if (response != undefined) {
          this.setState({
            getRegestrationList: response.appRegisration,
            noOfRecords: response.count.count

          })
        }
      })
      .catch((error) => {
        console.log(error, "catch the loop")
      })
  }

  activeUsers(status) {

    if (this.state.activeArray != "" && this.state.activeArray.length != "0") {
      if (status == true) {
        var url = baseUrl.URLPath + "v1.0/tenant/activate/" + this.state.activeArray;
      } else {
        var url = baseUrl.URLPath + "v1.0/tenant/deactivate/" + this.state.activeArray;
      }
      // let data = {"id":this.state.activeArray, "active":status}; 
      fetch(url, {
        method: "GET",
        //body: JSON.stringify(data), // data can be `string` or {object}!
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization")
        }
      })
        .then((resp) => {

          return resp.json();
        })
        .then((response) => {
          if (status == 0) {
            this.toggle1()

          } else {
            this.toggle2()
          }
        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })
    } else if (this.state.activeArray == "" && this.state.activeArray.length == "0") {
      this.toggleConfirmation();
    }
  }

  resetApp(appId) {

        var url = baseUrl.URLPath + "application/credential/download?registerId=" + appId;
      // let data = {"id":this.state.activeArray, "active":status}; 
      fetch(url, {
        method: "GET",
        //body: JSON.stringify(data), // data can be `string` or {object}!
        headers: {
          "content-type": "application/json",
          "Authorization": localStorage.getItem("Authorization")
        }
      })
        .then((resp) => {
          if (resp.status == 401) {
            this.togglesession();
            localStorage.clear();
          }
          if (resp.status == 200) {
            return resp.blob();
          }
        })
        .then((response) => {
          debugger
          let d = new Date();
          let dformat = `${d.getDate().toString().padStart(2, "0")}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d.getFullYear()}_${d.getHours()}-${d.getMinutes()}`;
          let url = window.URL.createObjectURL(response);
          let a = document.createElement('a');
          a.href = url;
          a.download = 'Regestered_Details_' + dformat + '.csv';
          a.click();
          // this.toggle();
          // this.props.history.push('/appRegestration');
        })
        .catch((error) => {
          console.log(error, "catch the loop")
        })
   
  }


  

  componentDidMount() {
    this.state.user.permissions = localStorage.getItem("roleAccess");
    this.getAppRegList(this.state.pageSize, this.state.activePage);
  }


  deleteUser() {
    debugger;
    this.setState({
      modal1: !this.state.modal1
    });

    var url = baseUrl.URLPath + "application/" + this.state.activeArray;
    //localhost/cpms/api/application/{id}
    fetch(url,
      {
        method: 'DELETE', // or 'PUT'
        //  body: JSON.stringify("NULL"), // data can be `string` or {object}!
        headers: {
          'Content-Type': 'application/json',
          "Authorization": localStorage.getItem("Authorization"),
        }
      }
    ).then((resp) => {
      if (resp.status == 401) {
        this.togglesession();
        localStorage.clear();
        
      }
      else if (resp.status == 400) {

      }
      else if (resp.status == 200) {
        this.setState({
          modal: !this.state.modal
        });
      }
    })
      .catch(error => alert('Error:' + error));

  }


  render() {
    const user = this.state.user
    const getListArray = this.state.getRegestrationList.map((getRegestrationList, index) => {

      if (getRegestrationList.status == true) {
        getRegestrationList.status = "Active";
      } else {
        if (getRegestrationList.status == false) {
          getRegestrationList.status = "Inactive";

        }
      }
      let blocked = false;
      if (getRegestrationList.loginAudit.blocked == true) {
        blocked = true;
      } else {
        blocked = false;
      }

      let redirection = false;
      if (getRegestrationList.redirection == true) {
        redirection = "true";
      } else {
        redirection = "false";
      }

      return (

        <tr>
          <td className="text-center">
            <Form.Check
              custom
              type="checkbox"
              id={`custom-${getRegestrationList.id}`}
              label=""
              onChange={this.handleChange.bind(this, `custom-${getRegestrationList.id}`, getRegestrationList.id)}
              key={getRegestrationList.id}
              className="checkbox"
            />
          </td>
          <td>
          <Link to={{
                pathname:'/viewRegester', 
                state:getRegestrationList.id, 
                }}>{getRegestrationList.applicationName}
              </Link>
          </td>
          <td>{getRegestrationList.applicationType}</td>
          <td className='width-d'>{getRegestrationList.domain.join(', ')}</td>
          <td className='width-d'>{getRegestrationList.redirectURL}</td>
          <td>{getRegestrationList.desc}</td>
          <td>{getRegestrationList.status}</td>
          <td>{(blocked == true) ? <a onClick={this.togglemodal.bind(this, getRegestrationList.loginAudit.errorCode, getRegestrationList.loginAudit.desc, getRegestrationList.loginAudit.noOfAttemts, getRegestrationList.loginAudit.apiUserId)}>YES</a> : <span>NO</span>}</td>
          <td>{redirection}</td>
          <td><button type="button" className="btn_primary" onClick={this.invalidate.bind(this, getRegestrationList.id)}>Invalidate</button></td>
          <td className='text-center'>
          <Link to={{
                pathname:'/editRegestration', 
                state:getRegestrationList.id, 
                }}><i class="fas fa-pencil-alt pr-2 action-icon " title="Edit"></i>

              </Link>
            <i class="fa fa-refresh   pr-2 action-icon " title="Reset" onClick={this.resetApp.bind(this, getRegestrationList.id)}></i>
          </td>

        </tr>
      )


    })
    return (
      <>
        <main class="content-div" >
          <p>App Registration
            {/* <div className="breadcrumb_div">
              Settings &gt; <span className="breadcrumb_page"> Users</span>
            </div> */}
          </p>
          <div className="page-outerdiv">




            <Alert color={this.state.colour} isOpen={this.state.sucess}>{this.state.message}</Alert>
            <div className="row">




              <div className="col-md-12 ">
                <div className="menubar pull-left">
                  <button onClick={this.addNew}>
                    <i className="fa fa-plus">
                    </i>
                    <span>Add</span>
                  </button>
                  {/* <button>
                    <i className="fas fa-pencil-alt">
                    </i>
                    <span>Edit</span>
                </button> */}


                  <button onClick={this.toggleConfirmation}>
                    <i className="fas fa-trash">
                    </i>
                    <span>Delete</span>
                  </button>
                </div>

              </div>


            </div>
            <div className="row  mt-3">

              {/* <MDBCol md="3"  className="mt-2" >
              <div class="input-group">
                <i className="fas fa-search fa-sm input-searchIcon"></i>
                <input type="text" id="emailId" onKeyDown={this.keyPress} value={this.state.fields["emailId"]} style={{ width: "60%" }} onChange={this.changeHandler.bind(this, "emailId")} class="form-control input-searchBox pad-2" placeholder="Email" name="adminAddress" onFocus={this.Focus} />
                <button type="button" data-test="button" className="closinginput" aria-label="Close"  onClick={this.handleClick} >
                  <span aria-hidden="true">×</span>
                </button>
                <div class="input-group-btn">
                  <button class="input-searchBtn find-btn" type="submit" onClick={this.onChangeEmail} >Find</button>
                </div>
                <span className="error_msg w3-animate-top mt-2"><span style={{color: "red"}}>{this.state.errors["selectChargepoint"]}</span></span>
                  <span className="error_msg w3-animate-top mt-2" id="notexists" style={{color: "red"}}>  <span style={{color: "red"}}>{this.state.errors["notexists"]}</span></span>
              </div>
             

        </MDBCol> */}
              {/* <div className=" col-md-3  pull-right mt-0">
      <select className="browser-default custom-select select_height " id="organizationid"  onChange={this.onChangeOrg.bind(this)}>
    {organisationDTOS}
    </select>
    </div> */}
              <MDBCol className="md-6"></MDBCol>
              <div className="col-md-3"></div>
              <div className="col-md-3 mt-2">
                <div className="pull-right">
                  <label className="sub-text pull-left" >Show entries</label>
                  <Form.Group controlId="exampleForm.ControlSelect1" className="custom_select mb-0 pull-left ml-0 selectdiv" >
                    <Form.Control as="select" className="showentry_sel custom_selectBox" id="showEntries" onChange={this.showEntriesChange}>
                      <option>10</option>
                      <option>20</option>
                      <option>30</option>
                      <option>40</option>
                      <option>50</option>
                    </Form.Control>
                  </Form.Group>
                </div>
              </div>

            </div>





            {/* {/ end page menu bar  /}
          
          {/ table start /} */}
            <div className="row mt-2 ">
              <div className="col-md-12">
                <Table striped hover size="sm" className="page-table">

                  <thead>
                    <tr>
                      <th className="text-center">
                        {/* <Form.Check 
                          custom
                          type="checkbox"
                          id={`custom-all`}
                          label = ""
                       /> */}
                      </th>
                      <th>Application Name</th>
                      <th>Application Type</th>
                      <th>Application Domain or IP</th>
                      <th>Redirect URL</th>
                      <th>Description</th>
                      <th>Status</th>
                      <th>Is Blocked?</th>
                      <th>Is Redirections?</th>
                      <th>Invalidate</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getListArray}
                  </tbody>
                </Table>
              </div>
              <div className="col-md-12 mt-2 mb-4 text-right">
                <Pagination
                  hideDisabled
                  activePage={this.state.activePage}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.noOfRecords}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>

              {/* {/ End table  /} */}
            </div>
          </div>

          {/* {/ popup msg /} */}
          <MDBModal isOpen={this.state.modal3} toggle={this.toggle1} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle1}>Success</MDBModalHeader>
            <MDBModalBody>
              User deactivated successfully.
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="primary" onClick={this.tagOk1}>OK</MDBBtn>
            </MDBModalFooter>
          </MDBModal>
          {/* 
             {/ popup msg /} */}
          <MDBModal isOpen={this.state.modal2} toggle={this.toggle2} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle2}>Success</MDBModalHeader>
            <MDBModalBody>
              User activated successfully.
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="primary" onClick={this.tagOk2} >OK</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          {/* <MDBModal isOpen={this.state.modal1} toggle={this.toggleConfirmation} size="md" className="model_top">
      <MDBModalHeader toggle={this.toggleConfirmation}>Confirmation</MDBModalHeader>
      <MDBModalBody>
      Are you sure you want to delete?
      </MDBModalBody>
      <MDBModalFooter>
      <MDBBtn color="danger" onClick={this.toggleConfirmation}>CLOSE</MDBBtn>
      <MDBBtn color="primary" onClick={this.deleteUser}>YES</MDBBtn>
      </MDBModalFooter>
      </MDBModal> */}
          <MDBModal isOpen={this.state.modal1} toggle={this.toggleConfirmation} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggleConfirmation}> {this.state.deleteHeader}</MDBModalHeader>
            <MDBModalBody>
              {this.state.delete_msg}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color={this.state.deletecolor} onClick={this.toggleConfirmation}>{this.state.deleteText}</MDBBtn>
              <MDBBtn color="primary" className={this.state.deleteButton} onClick={this.deleteUser}>YES</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          <MDBModal isOpen={this.state.modal} backdrop="static" data-backdrop="static" data-keyboard="false" size="md" className="model_top">
            <MDBModalHeader toggle={this.toggle}>Success</MDBModalHeader>
            <MDBModalBody>
              Application deleted successfully.
            </MDBModalBody>
            <MDBModalFooter>
              {/* <MDBBtn color="secondary" onClick={this.toggle}>Close</MDBBtn> */}
              <MDBBtn color="primary" onClick={this.closeOkPopup}>OK</MDBBtn>
            </MDBModalFooter>
          </MDBModal>


          {/* popup for session expire */}

          <MDBModal isOpen={this.state.modalsession} toggle={this.togglesession} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglesession}>Session has expired</MDBModalHeader>
            <MDBModalBody>
              Please login again.
            </MDBModalBody>
            <MDBModalFooter>
              {/* <MDBBtn color="secondary" onClick={this.togglesession}>Close</MDBBtn> */}
              <MDBBtn color="primary" onClick={this.sessiontagOk}>OK</MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          {/* popup for invalidate session */}

          <MDBModal isOpen={this.state.modalinvalidate} toggle={this.toggleinvalidate} size="md" className="model_top">
            <MDBModalHeader toggle={this.toggleinvalidate}>Success</MDBModalHeader>
            <MDBModalBody>
              Session Invalidated Successfully.
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" className="btn_primary" onClick={this.toggleinvalidate}>OK</button>
            </MDBModalFooter>
          </MDBModal>

          {/* popup for release from block state */}

          <MDBModal isOpen={this.state.modalrelease} toggle={this.togglerelease} size="md" className="model_top">
            <MDBModalHeader toggle={this.togglerelease}>Success</MDBModalHeader>
            <MDBModalBody>
              Successfully released from blocked state.
            </MDBModalBody>
            <MDBModalFooter>
              <button type="button" className="btn_primary" onClick={this.togglerelease}>OK</button>
            </MDBModalFooter>
          </MDBModal>

          {/* Popup for  blocked info */}
          <MDBModal isOpen={this.state.viewblocked} toggle={this.toggleblocked} size="lg" className="model_top">
            <div data-test="modal-header" class="modal-header">
              <div class="modal-title fw-800 mt-1" ref={(node) => { if (node) { node.style.setProperty("font-size", "18px", "important"); } }}>View</div>
              <button type="button" class="close" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <MDBModalBody>
              <MDBRow className="mb-4 mt-3" >

                <MDBCol sm="12">
                  <div className="view-page-row view-bg-row" >
                    <label className="">
                      Error Code:
                    </label>
                    <label className="wrap-word">{this.state.errorCode}</label>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-4 mt-3" >

                <MDBCol sm="">
                  <div className="view-page-row view-bg-row">
                    <label className="w-40">
                      Description:
                    </label>
                    <label className="wrap-word">{this.state.desc}</label>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow className="mb-4 mt-3" >

                <MDBCol sm="">
                  <div className="view-page-row view-bg-row">
                    <label className="w-40">
                      No of Attempts:
                    </label>
                    <label className="wrap-word">{this.state.attempts}</label>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
              <button className="btn_primary mt-2 mb-2" onClick={this.releaseFromBlock.bind(this, this.state.Id)} type="button">Release</button>
            </MDBModalFooter>
          </MDBModal>

          {/* loader */}
          <div className="page_loader center" id="loader_image_div" style={{ display: "none" }}>
          <img src={process.env.PUBLIC_URL + "/assets/img/Loader.gif"} width="80" height="80" alt="" />
        </div>
          {/* end loader */}
          
        </main>
      </>
    );
  }
}

export default appRegestration;